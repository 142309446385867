<template>
  <div v-if="viewTypeTab === 'desktopViewTab'">
    <v-expansion-panels v-model="getPromoPanel" multiple focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>Message</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsTitleMessage :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Content Size</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionWindowSize :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>User Interaction</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionButtons :actions="actions" :model="model" :showCloseOptions="true" />
          <RfRetentionBehavior :model="model" :actions="actions" :clickOutsideSupported="true" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type === 'web'">
        <v-expansion-panel-header>Form</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionFieldSet :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Close Timer</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsBehavior :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Background</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionImageUpload :actions="actions" :model="model" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="hasInapp">
        <v-expansion-panel-header>In-App Purchase</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsInapp :actions="actions" :app="app" :model="model" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type !== 'web'">
        <v-expansion-panel-header>Deep Link</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsDeeplink :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type !== 'web'">
        <v-expansion-panel-header>Device Metadata</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsMetadata :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="!isMobileAndTvApp && model.path_type !== 'video'">
        <v-expansion-panel-header>Legal</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsLinks :model="model" :actions="actions" pathType="modal" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>CSS</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div class="promo-design-group">
            <RfPromoCustomCSS :actions="actions" />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
  <div v-else>
    <v-expansion-panels v-model="getPromoPanel" multiple focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>CONFIGURATION</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsMobileWeb :app="app" :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>CSS</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfPromoCustomCSS :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import RfRetentionActionsTitleMessage from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsTitleMessage.vue";
import RfRetentionActionsDeeplink from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsDeeplink.vue";
import RfRetentionActionsMetadata from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsMetadata.vue";
import RfRetentionActionsBehavior from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsBehavior.vue";
import RfRetentionBehavior from "@/components/RfPathCreate/RfRetentionActions/RfRetentionBehavior.vue";
import RfRetentionButtons from "@/components/RfPathCreate/RfRetentionActions/RfRetentionButtons.vue";
import RfRetentionActionsInapp from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsInapp.vue";
import RfRetentionActionsMobileWeb from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsMobileWeb.vue";
import RfRetentionWindowSize from "@/components/RfPathCreate/RfRetentionActions/RfRetentionWindowSize.vue";
import RfPromoCustomCSS from "@/components/RfPromotions/RfPromoCustomCSS.vue";
import RfRetentionFieldSet from "@/components/RfPathCreate/RfRetentionActions/RfRetentionFieldSet.vue";
import RfRetentionActionsLinks from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsLinks.vue";
import RfRetentionImageUpload from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionImageUpload.vue";
import { DefaultDeviceOptions } from "@/utils/constants/DevicesConstants";

export default {
  name: "RfRetentionActionsInterstitial",
  components: {
    RfRetentionActionsTitleMessage,
    RfRetentionActionsDeeplink,
    RfRetentionActionsBehavior,
    RfRetentionBehavior,
    RfRetentionButtons,
    RfRetentionActionsInapp,
    RfRetentionActionsMobileWeb,
    RfRetentionWindowSize,
    RfPromoCustomCSS,
    RfRetentionActionsMetadata,
    RfRetentionFieldSet,
    RfRetentionActionsLinks,
    RfRetentionImageUpload,
  },
  props: ["actions", "model", "app", "viewTypeTab", "actionGroup"],
  data() {
    return {
      getPromoPanel: [0],
    };
  },
  computed: {
    deviceType() {
      return this.model.device_type;
    },
    hasInapp() {
      return [
        DefaultDeviceOptions.roku_os.value,
        DefaultDeviceOptions.ios.value,
        DefaultDeviceOptions.android_os.value,
      ].includes(this.deviceType);
    },
    isMobileAndTvApp() {
      return (
        this.deviceType === "roku_os" || this.deviceType === "tv_os" || this.deviceType === "ios"
      );
    },
  },
  methods: {},
  watch: {},
};
</script>
