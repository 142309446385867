<template>
  <div>
    <GChart
      v-if="formattedMetricsData"
      :data="formattedMetricsData"
      :options="formatComboChartOptions"
      :create-chart="createComboChart"
    />
    <img v-else src="@/assets/images/placeholder/promo-barchart.jpg" width="780" />
  </div>
</template>

<script>
import MetricsUtils from "@/utils/MetricsUtils";
import MetricsMixin from "@/utils/MetricsMixin";
import { createComboChart } from "@/utils/vueGoogleChartUtils";
import { percentage } from "@/utils/metricsHelpers";
import { isAccept2Enabled } from "@/utils/prompts/promptHelpers";

const metricsAxisNames = ["Users", "Conversion Rate"];

export default {
  name: "RfPromotionChart",
  props: [
    "metrics",
    "metricsKey",
    "compareMetricsKey",
    "hasCustomGoal",
    "singleDayCustom",
    "actions",
    "customGoalInteractionName",
    "goalsTitle",
    "isInvisible",
    "model",
    "isPushNotification",
  ],
  mixins: [MetricsMixin],
  setup: () => ({ createComboChart }),
  computed: {
    hasMetricsData() {
      if (!this.metrics.length || !this.metricsKey || !this.metrics[0][this.metricsKey]) {
        return false;
      }
      return true;
    },

    hasMetricValues() {
      return this.metrics[0][this.metricsKey].data.find(
        item => item.count !== null && item.count > 0,
      );
    },

    getMaxChartValue() {
      const metricValues = this.metrics.reduce(
        (total, metric) => [
          ...total,
          ...(metric ? metric[this.metricsKey].data.map(item => item.count) : []),
        ],
        [],
      );
      const chartCeilingRounder = 10;
      const value = Math.max(...metricValues, chartCeilingRounder);
      return Math.ceil(value / chartCeilingRounder) * chartCeilingRounder;
    },
    formattedMetricsData() {
      if (!this.hasMetricsData) return;
      const currentMetric = this.metrics[0][this.metricsKey];
      const timezone = 0; // this.metrics.tz_offset;
      let horizontalLabels = currentMetric.data.map(item =>
        MetricsUtils.metricDateTimeFormatter(
          this.metricsKey,
          item.date,
          timezone,
          null,
          this.singleDayCustom,
        ),
      );

      if (this.metricsKey === "today" || this.metricsKey === "yesterday" || this.singleDayCustom) {
        horizontalLabels = horizontalLabels.map((item, index) => {
          const isTimeShown = (index + 1) % 4 === 1;
          return isTimeShown ? item : "";
        });
      }

      const data = this.metrics.map(metric => metric[this.metricsKey].data.map(item => item.count));
      if (data.every(row => row.every(cell => cell === null))) {
        return null;
      }
      const percentArr = this.constructPercentagesData(horizontalLabels.length, data, 2, 1, 0);

      const allData = [...data];
      if (!this.isPushNotification) allData.push(percentArr);

      const formattedData = this.standardData(allData, horizontalLabels);
      const accept1Label = this.customGoalInteractionName || "Primary";

      const additionalLabels = [];

      if (this.hasCustomGoal) additionalLabels.push(this.goalsTitle);

      if (this.isPushNotification) {
        formattedData[0] = ["Date", "Endpoints", "Attempted Deliveries", "Deliveries"];
      } else {
        formattedData[0] = [
          "Date",
          "Users",
          accept1Label,
          ...additionalLabels,
          `${this.isInvisible ? this.goalsTitle : accept1Label} Conv. Rate %`,
        ];
      }

      if (this.isInvisible) {
        formattedData.map(el => el.splice(2, this.hasCustomGoal ? 1 : 2));
      }

      return formattedData;
    },

    formatComboChartOptions() {
      if (!this.hasMetricsData) return;

      let customGoalColor = [];
      if (this.hasCustomGoal) {
        customGoalColor = ["#4D96E6"];
      }
      const opts = {
        width: "100%",
        height: 350,
        animation: { duration: 1000, easing: "inAndOut", startup: true },
        backgroundColor: { fill: "transparent" },
        legend: { position: "none" },
        focusTarget: "category",
        tooltip: { isHtml: "true" },
        colors: ["#70B9F7", "#336699", ...customGoalColor, "#67AC5C"],
        lineDashStyle: [4, 4],
        pointSize: 10,
        vAxes: {
          0: {
            title: metricsAxisNames[0],
            format: "short",
            viewWindow: { min: 0, max: this.getMaxChartValue },
            baselineColor: "#cccccc",
            gridlines: { color: "#eee", count: 4 },
            minorGridlines: { color: "#eeeeee", count: 0 },
          },
          1: {
            title: metricsAxisNames[1],
            format: "short",
            viewWindow: { min: 0, max: 100 },
            baselineColor: "#cccccc",
            gridlines: { color: "#eee", count: 0 },
            minorGridlines: { color: "#eeeeee", count: 0 },
          },
        },
        bar: { groupWidth: "80%" },
        seriesType: "bars",

        chartArea: { left: 70, top: 25, width: "90%", height: "82%" },
      };
      if (!this.isPushNotification)
        opts.series = {
          [this.metrics.length]: {
            type: "line",
            curveType: "function",
            title: "CTR",
            format: "# $",
            targetAxisIndex: 1,
          },
        };
      if (this.metricsKey === "this_month" || this.metricsKey === "last_month") {
        opts.hAxis = { maxAlternation: 1, showTextEvery: 2 };
      }
      return opts;
    },
  },
  methods: {
    standardData(data, horizontalLabels) {
      const formattedData = horizontalLabels.map((label, index) => {
        return [label].concat(data.map(item => item[index]));
      });
      const labelColumn = 1;
      const columns = data.length + labelColumn;
      const header = new Array(columns).fill("");
      return [header, ...formattedData];
    },
    constructPercentagesData(dataLength, data, custom_goal_index, click_index, denominator_index) {
      const percentArr = []; // change calc based on islegacydisplay
      // order can be impressions, accept 1, CTR 1
      // or impressions, accept 1, goal 1, CTR 1
      // or impressions accept 1, goal 1, accept 2, goal 2, CTR 1, CTR 2
      // or impressions accept 1, accept 2, CTR 1, CTR 2
      // the chart has a dependency on the order in which metrics are passed in
      for (let i = 0; i < dataLength; i++) {
        if (data[denominator_index][i] === null || data[click_index][i] === null) {
          percentArr.push(null);
        } else if (this.isInvisible && !this.hasCustomGoal) {
          percentArr.push(null);
        } else {
          // clicks or users
          const denominator =
            (this.isInvisible ? data[click_index][i] : data[denominator_index][i]) || 0; // impressions or users
          // custom goals or clicks
          const numerator =
            (this.hasCustomGoal ? data[custom_goal_index][i] : data[click_index][i]) || 0;

          const percent = percentage(numerator, denominator);
          percentArr.push(percent);
        }
      }
      return percentArr;
    },
  },
  mounted() {},
};
</script>
