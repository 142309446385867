<template>
  <div class="bg-white-1 flex flex-col gap-3 rounded p-5">
    <div class="flex items-center justify-between">
      <div class="flex flex-col gap-1">
        <h2 class="text-heading-1 !text-black-1">Actions</h2>
        <div class="text-action-buttons" v-if="!actionsLengthTotal">
          No actions for this prompt yet
        </div>
      </div>
      <RfButtonNew :disabled="props.disabled" text="+ Add action" @click="emit('add')" />
    </div>
    <div v-if="actionsLengthTotal" class="flex flex-col gap-3">
      <RfButtonTabs v-if="interactionKeys.length" :active="tab">
        <RfButtonNew
          v-for="(key, i) in interactionKeys"
          type="none"
          class="px-4 py-2 !text-black-2"
          :class="{ '!font-bold': i === tab }"
          @click="tab = i"
        >
          {{ StringUtils.capitalize(displayInteractionType(props.prompt, key)) }}
        </RfButtonNew>
      </RfButtonTabs>
      <RfTable
        class="text-body w-full overflow-auto !text-black-2"
        :tableData="{ totalCount: actionsLengthTotal }"
      >
        <template #thead>
          <tr>
            <td class="w-20 px-4 pl-0 pr-2">Priority</td>
            <td class="w-40 px-4 py-2">Provider</td>
            <td class="px-4 py-2">Action</td>
            <td class="w-24 px-4 py-2 text-center">Status</td>
            <td class="w-80 px-4 py-2">Value</td>
            <td class="w-14 px-4 py-2"></td>
          </tr>
        </template>
        <template #tbody>
          <template v-if="currentTabClientActions">
            <tr
              v-for="clientAction in currentTabClientActions"
              :key="`${clientAction.id}-${clientAction.interaction}`"
              class="h-full border-t border-solid border-strokes"
            >
              <td></td>
              <td>
                <span class="whitespace-nowrap"> Redfast </span>
              </td>
              <td class="text-heading-2 !text-black-2">
                {{
                  isRedirectAction(clientAction.actionName)
                    ? `Redirect to '${prompt.actions[clientAction.actionName]}'`
                    : clientAction.name
                }}
              </td>
              <td>
                <div class="flex h-full w-full">
                  <RfErrorStatusIcon v-if="clientAction?.error" class="m-auto !h-6 !w-6" />
                  <RfCheckIcon v-else class="m-auto !h-6 !w-6 !fill-green-2" />
                </div>
              </td>
              <td>
                <ul v-if="!isRedirectAction(clientAction.actionName)">
                  <li v-for="(value, key) in clientAction.args">
                    {{ `${key}: ${value}` }}
                  </li>
                </ul>
              </td>
              <td>
                <div class="flex items-center justify-end gap-3">
                  <RfButtonNew
                    v-if="!isRedirectAction(clientAction.actionName)"
                    :disabled="props.disabled"
                    type="none"
                    size="none"
                    class="h-6 w-6"
                    @click="emit('editClientAction', clientAction.action)"
                  >
                    <RfEditIcon class="!h-6 !w-6" />
                  </RfButtonNew>
                  <RfButtonNew
                    :disabled="props.disabled"
                    type="none"
                    size="none"
                    class="h-6 w-6"
                    @click="emit('deleteClientAction', clientAction)"
                  >
                    <RfDeleteIcon class="!h-6 !w-6" />
                  </RfButtonNew>
                </div>
              </td>
            </tr>
          </template>
          <draggable
            v-if="currentTabConnectorActions?.length"
            :value="currentTabConnectorActions"
            :dragOptions="{
              animation: 0,
              group: 'description',
              disabled: false,
              ghostClass: 'ghost',
            }"
            :disabled="props.disabled"
            tag="div"
            key="draggable"
            class="contents"
            @input="updateActions"
          >
            <tr
              v-for="(connectorAction, index) in currentTabConnectorActions"
              :key="connectorAction.id"
              class="h-full border-t border-solid border-strokes"
            >
              <td class="p-2 pl-0">
                <div class="h-full w-full">
                  <span class="flex cursor-grab items-center gap-2">
                    <RfDragIcon v-if="!props.disabled" class="!h-6 !w-6" />
                    {{ index + 1 }}
                  </span>
                </div>
              </td>
              <td>
                {{ StringUtils.capitalize(connectorAction.connector_action.provider) || "Redfast" }}
              </td>
              <td class="text-heading-2 !text-black-2">
                {{ connectorAction.connector_action.args.description }}
              </td>
              <td>
                <div class="flex h-full w-full">
                  <RfCheckIcon
                    v-if="
                      !isActionDisabled(
                        connectorAction.connector_action.args,
                        connectorAction.interaction_type,
                        prompt,
                        props.providers,
                      )
                    "
                    class="m-auto !h-6 !w-6 !fill-green-2"
                  />
                  <RfErrorStatusIcon v-else class="m-auto !h-6 !w-6" />
                </div>
              </td>
              <td>
                <template v-if="connectorAction.type !== 'checkbox'">
                  <ul>
                    <li v-for="(value, key) in connectorAction.args" :key="key">
                      {{
                        displayConnectorAction({
                          key,
                          value,
                          prompt,
                          inputs: connectorAction.connector_action.args.inputs,
                          currAppConnectors: props.currAppConnectors,
                          currLanguage: props.currLanguage,
                        })
                      }}
                    </li>
                  </ul>
                </template>
                <template v-else> {{ connectorAction.args }}</template>
              </td>
              <td>
                <div class="flex items-center justify-end gap-3">
                  <RfButtonNew
                    v-if="connectorAction.type !== 'checkbox'"
                    :disabled="props.disabled"
                    type="none"
                    size="none"
                    class="h-6 w-6"
                    @click="emit('editIntegration', connectorAction)"
                  >
                    <RfEditIcon class="!h-6 !w-6" />
                  </RfButtonNew>
                  <RfButtonNew
                    :disabled="props.disabled"
                    type="none"
                    size="none"
                    class="h-6 w-6"
                    @click="emit('deleteIntegration', connectorAction)"
                  >
                    <RfDeleteIcon class="!h-6 !w-6" />
                  </RfButtonNew>
                </div>
              </td>
            </tr>
          </draggable>
        </template>
      </RfTable>
    </div>
  </div>
</template>

<script setup>
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import RfButtonTabs from "@/components/buttons/RfButtonTabs.vue";
import draggable from "vuedraggable";
import { computed, ref } from "vue";
import { displayInteractionType } from "@/utils/prompts/promptHelpers";
import StringUtils from "@/utils/StringUtils";
import RfDragIcon from "@/components/icons/RfDragIcon.vue";
import {
  displayConnectorAction,
  getActiveActions,
  isActionDisabled,
  isRedirectAction,
} from "@/utils/prompts/promptActionHelpers";
import RfCheckIcon from "@/components/icons/RfCheckIcon.vue";
import RfEditIcon from "@/components/icons/RfEditIcon.vue";
import RfDeleteIcon from "@/components/icons/RfDeleteIcon.vue";
import RfTable from "@/blocks/RfTable/RfTable.vue";
import { groupBy } from "@/utils/arrayUtils";
import RfErrorStatusIcon from "@/components/icons/RfErrorStatusIcon.vue";

const emit = defineEmits([
  "add",
  "editClientAction",
  "deleteClientAction",
  "editIntegration",
  "deleteIntegration",
  "updateActions",
]);
const tab = ref(0);

const props = defineProps({
  disabled: { type: Boolean, default: true },
  currLanguage: { type: String, default: null },
  currAppConnectors: { type: Object, default: () => ({}) },
  prompt: { type: Object, default: () => ({}) },
  actionGroupConnectorActionsGroupedByType: { type: Object, default: () => ({}) },
  providers: { type: Object, default: () => ({}) },
  appClientActions: { type: Array, default: () => [] },
});

const actionGroupConnectorActionsGroupedByType = computed(() =>
  groupBy(props.prompt.action_group_connector_actions || [], "connectable_type"),
);

const clientActions = computed(() =>
  getActiveActions(props.prompt, actionGroupConnectorActionsGroupedByType.value.ClientAction).map(
    ([key, item, args]) => {
      if (isRedirectAction(item)) {
        return {
          id: "redirect",
          name: "Redirect",
          value: key,
          interaction: key.replace("_redirect", ""),
          actionName: item,
        };
      }
      const agca =
        actionGroupConnectorActionsGroupedByType.value.ClientAction.find(action => {
          const interactionMatch =
            action.interaction_type == item && action.connectable_type === "ClientAction";
          return (
            interactionMatch && args?.survey_option_selected === action.args?.survey_option_selected
          );
        }) || {};
      const clientAction = props.appClientActions.find(
        action => action.value === agca.connector_action.id,
      ) || {
        text: "WEBSITE ACTION DELETED; PLEASE REMOVE",
        value: null,
        error: true,
      };
      return {
        name: clientAction.text,
        value: clientAction.value,
        interaction: key,
        actionName: item,
        error: clientAction.error,
        args: agca.args,
        id: agca.id,
        action: agca,
      };
    },
  ),
);

const currentTabConnectorActions = computed(() =>
  (actionGroupConnectorActionsGroupedByType.value.ConnectorAction || []).filter(
    el => el.interaction_type === interactionKeys.value[tab.value],
  ),
);

const currentTabClientActions = computed(() =>
  (clientActions.value || []).filter(el => el.interaction === interactionKeys.value[tab.value]),
);

const interactionKeys = computed(() => {
  const connectorKeys = Object.keys(
    groupBy(props.prompt.action_group_connector_actions || [], "interaction_type"),
  );
  const clientActionKeys = Object.keys(groupBy(clientActions.value || [], "interaction"));
  const keysArray = connectorKeys.concat(clientActionKeys);
  return [...new Set(keysArray)].sort();
});

const actionsLengthTotal = computed(
  () =>
    (clientActions.value?.length || 0) +
    (actionGroupConnectorActionsGroupedByType.value?.ConnectorAction?.length || 0),
);

const updateActions = value => emit("updateActions", value);
</script>
