<template>
  <label class="rf-checkbox--container">
    <span class="inline-block h-6 w-6" aria-hidden="true"></span>
    <span v-if="!!$slots.default" :class="{ 'ml-2': !!$slots.default }">
      <slot></slot>
    </span>
    <input v-model="model" type="checkbox" class="rf-checkbox--input" />
    <span v-if="intermediate" class="rf-checkbox--intermediate"></span>
    <span class="rf-checkbox--checkmark" aria-hidden="true"></span>
  </label>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    intermediate: { type: Boolean, default: false },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.rf-checkbox {
  &--container {
    @apply text-action-buttons relative flex cursor-pointer select-none items-center;

    &:hover {
      .rf-checkbox--checkmark {
        box-shadow: 0px 4px 12px rgba(92, 104, 124, 0.16);
      }
    }
  }

  &--intermediate {
    @apply absolute inset-x-1 top-1/2 h-0.5 rounded-sm bg-blue-2 opacity-100;
    transform: translateY(-50%);
  }

  &--checkmark {
    @apply absolute left-0 top-1/2 h-6 w-6 rounded-sm;
    transform: translateY(-50%);

    &::after {
      @apply border-white-1 absolute hidden border-solid;
      content: "";
      left: 9px;
      top: 5.5px;
      width: 5.5px;
      height: 10px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .rf-checkbox--checkmark {
      @apply bg-blue-1;

      &::after {
        display: block;
      }
    }

    &:not(:checked) ~ .rf-checkbox--checkmark {
      @apply border-solid border-blue-2;
      border-width: 1px;
    }

    &:not(:checked) ~ .rf-checkbox--intermediate {
      @apply opacity-100;
    }

    &:focus-visible ~ .rf-checkbox--checkmark {
      box-shadow: 0px 4px 12px rgba(92, 104, 124, 0.16);
    }
  }
}
</style>
