import { InlinePrompts, PromptTypes, StandalonePrompts } from "../constants/PromoTypesConstants";
import { HorizontalPositions, VerticalPositions } from "../constants/PositionConstants";
import PromoInteractionConstants from "../constants/PromoInteractionConstants";
import { DefaultDeviceOptions } from "../constants/DevicesConstants";

export const isPlacement = pathType => InlinePrompts?.[pathType]?.value;
export const isStandalone = (prompt = {}) => StandalonePrompts?.[prompt.path_type]?.value;
export const isModal = (prompt = {}) =>
  prompt.path_type !== StandalonePrompts.email.value &&
  StandalonePrompts?.[prompt.path_type]?.value;
export const isOverlay = (prompt = {}) =>
  [
    StandalonePrompts.retention_modal.value,
    StandalonePrompts.widget.value,
    StandalonePrompts.interstitial.value,
    StandalonePrompts.video.value,
    StandalonePrompts.bottom_banner.value,
  ].includes(prompt?.path_type);
export const isStandaloneWeb = (prompt = {}) =>
  prompt?.device_type === DefaultDeviceOptions.web.value &&
  [
    StandalonePrompts.retention_modal.value,
    StandalonePrompts.widget.value,
    StandalonePrompts.invisible.value,
    StandalonePrompts.interstitial.value,
    StandalonePrompts.video.value,
    StandalonePrompts.bottom_banner.value,
  ].includes(prompt?.path_type);

export const displayInteractionType = (prompt, type) => {
  if (type === PromoInteractionConstants.accept.value)
    return (
      getButtonTitle(prompt, "rf_retention_button1_text") ||
      (prompt.path_type === "invisible" ? "accept" : "button 1")
    );
  if (type === PromoInteractionConstants.accept2.value)
    return getButtonTitle(prompt, "rf_retention_button2_text") || "button 2";
  if (type === PromoInteractionConstants.decline.value)
    return getButtonTitle(prompt, "rf_retention_button3_text") || "button 3";
  return type;
};

export const getButtonTitle = (prompt, key) => {
  if (!prompt?.filter?.is_translated) return prompt?.actions?.[key];
  return Object.values(prompt?.parsed_translations || {}).find(e => e?.default === "TRUE")?.[key];
};

export const isEmail = (prompt = { path_type: null }) =>
  prompt.path_type === PromptTypes.email.value;

export const isInvisible = (prompt = { path_type: null }) =>
  prompt.path_type === PromptTypes.invisible.value;

export const isCustomGoalExists = (prompt = { custom_filter: {} }) =>
  !!Object.keys(prompt.custom_filter || {}).length;

export const isAccept2Enabled = (prompt = { actions: {} }) =>
  [true, "true"].includes(prompt?.actions?.rf_settings_confirm_button_2_enabled);

export const isSurvey = (prompt = { actions: {} }) =>
  [true, "true"].includes(prompt?.actions?.rf_retention_survey_selected);

export const getPosition = (actions, mobile = false) => {
  const key = mobile ? "rf_mobile_widget_position" : "rf_widget_position";
  const pos = { y: VerticalPositions.bottom.value, x: HorizontalPositions.right.value };
  if (!actions?.[key]) return pos;

  const [verticalPos, horizontalPos] = actions[key].split("_");

  if (VerticalPositions[verticalPos]) pos.y = VerticalPositions[verticalPos].value;
  if (HorizontalPositions[horizontalPos]) pos.x = HorizontalPositions[horizontalPos].value;
  return pos;
};

export const isLatestExperimentActive = ({ path, experiments }) => {
  if (path.experiment) return !!path.experiment.is_active && !path.experiment.is_completed;
  if (Array.isArray(experiments)) return !!experiments.at(-1)?.is_active;
  return false;
};

export const isLocalized = prompt => prompt?.filter?.is_translated;
export const isPushNotification = prompt => prompt?.path_type === PromptTypes.scheduled_push.value;
