<template>
  <div class="rf-prompt-chart flex flex-col gap-3 rounded" @click.middle="skeleton = !skeleton">
    <Transition mode="out-in">
      <div class="contents" v-if="skeleton">
        <div
          class="grid grid-cols-3 gap-2 gap-y-6 border-y border-solid border-transparent px-4 py-2 lg:grid-cols-6"
        >
          <div v-for="i in 6" :key="i" class="flex flex-col gap-2">
            <RfBaseSkeleton height="20" class="!w-5/6" width="146" />
            <RfBaseSkeleton height="32" class="!w-2/3" width="106" />
            <div class="flex items-center gap-1.5">
              <RfBaseSkeleton height="24" width="24" radius="9999" />
              <RfBaseSkeleton height="16" class="!w-1/6" width="29" />
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2 pt-6">
          <div class="rf-prompt-chart--chart flex items-start gap-5">
            <div class="flex flex-grow-0 flex-col items-end gap-6">
              <RfBaseSkeleton v-for="i in 5" :key="i" width="40" height="16" />
              <RfBaseSkeleton width="24" height="16" />
            </div>
            <div class="flex w-px flex-col">
              <div
                class="bg-stripes mt-2 flex h-52 flex-grow gap-2 before:border-b-2 before:border-solid before:border-b-grey-1"
              >
                <div
                  v-for="i in 6"
                  :key="i"
                  class="flex h-52 flex-col items-center justify-end gap-3"
                  style="margin-top: 26px"
                >
                  <RfBaseSkeleton
                    height="100"
                    dynamicHeight
                    rangeHeight="50"
                    class="!w-full !max-w-24 !rounded-b-none"
                  />
                  <RfBaseSkeleton width="40" height="16" />
                </div>
              </div>
            </div>
          </div>
          <div v-show="singleStatByDefault" class="rf-prompt-chart--legend-container"></div>
        </div>
      </div>
      <div
        v-else-if="!hasData"
        class="!mb-0.5 !mt-0.5 flex flex-col gap-2 self-center !pb-14 !pt-14 lg:!mb-0 lg:!mt-0 lg:!pb-0 lg:!pt-0"
      >
        <NoChartDataPlaceholder
          class="pt-10"
          body="There is no data yet. Data is displayed when a prompt(s) has been running for at least two hours."
          style="height: 395px"
        />
        <div v-show="singleStatByDefault" class="rf-prompt-chart--legend-container"></div>
      </div>
      <div class="contents" v-else>
        <div
          ref="statsButtonsContainer"
          class="grid-max-cols-3 lg:grid-max-cols-6 grid-cols-auto-stretch grid gap-2 gap-y-6 border-y border-solid border-strokes px-0 py-0"
        >
          <button
            class="box-border grid place-items-start border-solid border-blue-1 px-4 py-2"
            :class="{ 'bg-white-2 -mb-0.5 border-b-2': selectedChartKey === 1 }"
            @click="selectChartKey(1)"
          >
            <RfStatsCard
              title="Endpoints"
              class="bg-opacity-0 !p-0 text-start"
              :value="toLocaleNumberString(100000000)"
              :grow="100"
              :loading="false"
              :timePeriod="METRICS_PERIODS.last_seven_to_fourteen_days.value"
            />
          </button>

          <!-- <template v-if="isPushNotificationComputed">
            <button
              class="grid place-items-start px-4 py-2"
              :class="{ 'bg-strokes': selectedChartKey === 1 }"
              @click="selectChartKey(1)"
            >
              <RfStatsCard
                title="Endpoints"
                class="bg-opacity-0 !p-0"
                :value="toLocaleNumberString(uniqueEndpointsGroupedByDate)"
                :grow="0"
                :timePeriod="''"
                :timePeriodName="''"
              />
            </button>
            <button
              class="grid place-items-start px-4 py-2"
              :class="{ 'bg-strokes': selectedChartKey === 2 }"
              @click="selectChartKey(2)"
            >
              <RfStatsCard
                title="Attempted Deliveries"
                class="bg-opacity-0 !p-0"
                :value="toLocaleNumberString(attemptedDeliveriesGroupedByDate)"
                :grow="0"
                :timePeriod="''"
                :timePeriodName="''"
              />
            </button>
            <button
              class="grid place-items-start px-4 py-2"
              :class="{ 'bg-strokes': selectedChartKey === 3 }"
              @click="selectChartKey(3)"
            >
              <RfStatsCard
                title="Deliveries"
                class="bg-opacity-0 !p-0"
                :value="toLocaleNumberString(uniqueDeliveriesGroupedByDate)"
                :grow="0"
                :timePeriod="''"
                :timePeriodName="''"
              />
            </button>
          </template>
          <template v-else>
            <button
              class="grid place-items-start px-4 py-2"
              :class="{ 'bg-strokes': selectedChartKey === 1 }"
              :disabled="!singleStatByDefault"
              @click="selectChartKey(1)"
            >
              <RfStatsCard
                title="Users"
                class="bg-opacity-0 p-0"
                :value="
                  toLocaleNumberString(isInvisibleComputed ? totalGoalsUniques : totalUimpressions)
                "
                :grow="0"
                :timePeriod="''"
                :timePeriodName="''"
              />
            </button>
            <template v-if="!isInvisibleComputed">
              <button
                class="grid place-items-start px-4 py-2"
                :class="{ 'bg-strokes': selectedChartKey === 2 }"
                :disabled="!singleStatByDefault"
                @click="selectChartKey(2)"
              >
                <RfStatsCard
                  :title="isEmailComputed ? 'Sends' : 'Impressions'"
                  class="bg-opacity-0 p-0"
                  :value="toLocaleNumberString(totalImpressions)"
                  :grow="0"
                  :timePeriod="''"
                  :timePeriodName="''"
                />
              </button>
              <button
                class="grid place-items-start px-4 py-2"
                :class="{ 'bg-strokes': selectedChartKey === 3 }"
                :disabled="!singleStatByDefault || !totalCtr.value"
                @click="selectChartKey(3)"
              >
                <RfStatsCard
                  title="CTR"
                  class="bg-opacity-0 p-0"
                  :value="totalCtr.label"
                  :grow="0"
                  :timePeriod="''"
                  :timePeriodName="''"
                />
              </button>
              <button
                class="grid place-items-start px-4 py-2"
                :class="{ 'bg-strokes': selectedChartKey === 4 }"
                :disabled="!singleStatByDefault || !totalGoals"
                @click="selectChartKey(4)"
              >
                <RfStatsCard
                  title="Clicks"
                  class="bg-opacity-0 p-0"
                  :value="toLocaleNumberString(totalGoals)"
                  :grow="0"
                  :timePeriod="''"
                  :timePeriodName="''"
                />
              </button>
            </template>
            <template v-if="hasCustomGoal">
              <button
                class="grid place-items-start px-4 py-2"
                :class="{ 'bg-strokes': selectedChartKey === (isInvisibleComputed ? 2 : 5) }"
                :disabled="!singleStatByDefault || !totalCustomGoals"
                @click="selectChartKey(isInvisibleComputed ? 2 : 5)"
              >
                <RfStatsCard
                  title="Custom Goal"
                  class="bg-opacity-0 p-0"
                  :value="toLocaleNumberString(totalCustomGoals)"
                  :grow="0"
                  :timePeriod="''"
                  :timePeriodName="''"
                />
              </button>
              <button
                class="grid place-items-start px-4 py-2"
                :class="{ 'bg-strokes': selectedChartKey === (isInvisibleComputed ? 3 : 6) }"
                :disabled="!singleStatByDefault || !totalCustomGoals"
                @click="selectChartKey(isInvisibleComputed ? 3 : 6)"
              >
                <RfStatsCard
                  title="Conv. Rate"
                  class="bg-opacity-0 p-0"
                  :value="totalCustomGoalConversionRate.label"
                  :grow="0"
                  :timePeriod="''"
                  :timePeriodName="''"
                />
              </button>
            </template>
            <template v-else>
              <button
                class="grid place-items-start px-4 py-2"
                :class="{ 'bg-strokes': selectedChartKey === 5 }"
                :disabled="!singleStatByDefault || !totalConversionRate.value"
                @click="selectChartKey(5)"
              >
                <RfStatsCard
                  title="Conv. Rate"
                  class="bg-opacity-0 p-0"
                  :value="isInvisibleComputed ? '-' : totalConversionRate.label"
                  :grow="0"
                  :timePeriod="''"
                  :timePeriodName="''"
                />
              </button>
              <button
                v-if="accept2Enabled"
                class="grid place-items-start px-4 py-2"
                :class="{ 'bg-strokes': selectedChartKey === 6 }"
                :disabled="!singleStatByDefault || !conversionRateAdditionalValue.value"
                @click="selectChartKey(6)"
              >
                <RfStatsCard
                  title="Conv. Rate (additional)"
                  class="bg-opacity-0 p-0"
                  :value="conversionRateAdditionalValue.label"
                  :grow="0"
                  :timePeriod="''"
                  :timePeriodName="''"
                />
              </button>
            </template>
          </template>-->
        </div>
        <div class="flex flex-col gap-2 pt-6">
          <div class="rf-prompt-chart--chart">
            <canvas ref="chart"></canvas>
          </div>
          <div v-show="singleStatByDefault" class="rf-prompt-chart--legend-container"></div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<!-- <script setup>
import RfStatsCard from "@/components/cards/RfStatsCard.vue";
import RfNoChartDataPlaceholderAlter from "@/components/placeholder/RfNoChartDataPlaceholderAlter.vue";
import RfBaseSkeleton from "@/components/skeletons/RfBaseSkeleton.vue";
import { chartColors, useBarChart } from "@/utils/composables/useBarChart";
import { interactionDenominators } from "@/utils/constants/PromoInteractionConstants";
import { getTotals, percentage } from "@/utils/metricsHelpers";
import {
  isAccept2Enabled,
  isCustomGoalExists,
  isEmail,
  isInvisible,
  isPushNotification,
} from "@/utils/prompts/promptHelpers";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import { onClickOutside } from "@vueuse/core";
import { computed, onUnmounted, ref, watchEffect } from "vue";
const props = defineProps({
  skeleton: { type: Boolean, default: false },
  prompt: { type: Object, default: false },
  metrics: { type: Object, default: false },
  preset: { type: String, default: "last_seven_days" },
});
const chart = ref(null);
const stop = ref(null);
const statsButtonsContainer = ref(null);
const selectedChartKey = ref(null);
const colors = ref([...chartColors]);
const selectChartKey = key => {
  if (key === selectedChartKey.value) return (selectedChartKey.value = null);
  return (selectedChartKey.value = key);
};
const isPushNotificationComputed = computed(() => isPushNotification(props.prompt));
const isInvisibleComputed = computed(() => isInvisible(props.prompt));
const isEmailComputed = computed(() => isEmail(props.prompt));
const getPushNotificationTotalByKey = key =>
  props.metrics?.[key]?.[props.preset]?.uniques || props.metrics?.[key]?.[props.preset]?.total || 0;
const getPushNotificationByDay = key =>
  (props.metrics?.[key]?.[props.preset]?.data || []).map(el => [el.date, el.count]);
const uniqueEndpointsGroupedByDate = computed(() =>
  getPushNotificationTotalByKey("unique-endpoints-grouped-by-date"),
);
const attemptedDeliveriesGroupedByDate = computed(() =>
  getPushNotificationTotalByKey("attempted-deliveries-grouped-by-date"),
);
const uniqueDeliveriesGroupedByDate = computed(() =>
  getPushNotificationTotalByKey("unique-deliveries-grouped-by-date"),
);
const totalGoalsUniques = computed(() => getPushNotificationTotalByKey("goals"));
const totalUimpressions = computed(() => getPushNotificationTotalByKey("uimpressions"));
const totalImpressions = computed(() => getTotals(props.metrics, "impressions", props.preset));
const totalGoals = computed(() => getTotals(props.metrics, "goals", props.preset));
const totalGoals2 = computed(() => getTotals(props.metrics, "goals2", props.preset));
const totalCtr = computed(() => percentage(totalGoals.value, totalImpressions.value, "new"));
const hasCustomGoal = computed(() => isCustomGoalExists(props.prompt));
const accept2Enabled = computed(() => isAccept2Enabled(props.prompt));
const totalConversionRate = computed(() =>
  percentage(totalGoals.value, totalUimpressions.value, "new"),
);
const totalCustomGoals = computed(() => {
  if (isInvisibleComputed.value) return getPushNotificationTotalByKey("custom_goals_accepted");
  return getTotals(props.metrics, "custom_goals_seen", props.preset);
});
const totalCustomGoalConversionRate = computed(() =>
  percentage(
    totalCustomGoals.value,
    isInvisibleComputed.value ? totalGoalsUniques.value : totalUimpressions.value,
    "new",
  ),
);
const conversionRateAdditionalValue = computed(() =>
  percentage(
    totalGoals2.value,
    isInvisibleComputed.value ? totalGoalsUniques.value : totalUimpressions.value,
    "new",
  ),
);
const metricsFormatted = computed(() => {
  if (Object.keys(props.metrics).length === 0) return [];
  const chartData = [];
  if (isPushNotificationComputed.value) {
    chartData.push({
      label: "Endpoints",
      data: Object.fromEntries(getPushNotificationByDay("unique-endpoints-grouped-by-date")),
      backgroundColor: colors.value.splice(~~(Math.random() * colors.length), 1)[0],
    });
    chartData.push({
      label: "Attempted Deliveries",
      data: Object.fromEntries(getPushNotificationByDay("attempted-deliveries-grouped-by-date")),
      backgroundColor: colors.value.splice(~~(Math.random() * colors.length), 1)[0],
    });
    chartData.push({
      label: "Deliveries",
      data: Object.fromEntries(getPushNotificationByDay("unique-deliveries-grouped-by-date")),
      backgroundColor: colors.value.splice(~~(Math.random() * colors.length), 1)[0],
    });
  } else {
    chartData.push({
      label: "Users",
      data: Object.fromEntries(
        getPushNotificationByDay(
          isInvisibleComputed.value ? interactionDenominators.accepted : "uimpressions",
        ),
      ),
      backgroundColor: colors.value.splice(~~(Math.random() * colors.length), 1)[0],
    });
    if (!isInvisibleComputed.value) {
      chartData.push({
        label: "Impressions",
        data: Object.fromEntries(getPushNotificationByDay("impressions")),
        backgroundColor: colors.value.splice(~~(Math.random() * colors.length), 1)[0],
      });
      const customGoalConversionRate = () => {
        const customGoalsByDate = getPushNotificationByDay("goals");
        const customGoalsDenominator = getPushNotificationByDay("impressions");
        const res = [];
        for (let i = 0; i < customGoalsByDate?.length - 1; i++) {
          res.push({
            key: customGoalsByDate[i][0],
            value: {
              label: percentage(customGoalsByDate[i][1], customGoalsDenominator[i][1]),
              raw: 0,
            },
          });
        }
        return res;
      };
      chartData.push({
        label: "CTR",
        data: customGoalConversionRate(),
        backgroundColor: colors.value.splice(~~(Math.random() * colors.length), 1)[0],
        parsing: { xAxisKey: "key", yAxisKey: "value.raw" },
        props: { type: "percentage" },
      });
      chartData.push({
        // replace with name
        label: "Primary",
        data: Object.fromEntries(getPushNotificationByDay(interactionDenominators.accepted)),
        backgroundColor: colors.value.splice(~~(Math.random() * colors.length), 1)[0],
      });
      if (!hasCustomGoal.value) {
        const customGoalsByDate = getPushNotificationByDay("goals");
        const customGoalsDenominator = getPushNotificationByDay("uimpressions");
        const customGoalConversionRate = () => {
          const res = [];
          for (let i = 0; i < customGoalsByDate?.length - 1; i++) {
            res.push({
              key: customGoalsByDate[i][0],
              value: {
                label: percentage(customGoalsByDate[i][1], customGoalsDenominator[i][1]),
                raw: 0,
              },
            });
          }
          return res;
        };
        chartData.push({
          label: "Conv. rate",
          data: customGoalConversionRate(),
          backgroundColor: colors.value.splice(~~(Math.random() * colors.length), 1)[0],
          parsing: { xAxisKey: "key", yAxisKey: "value.raw" },
          props: { type: "percentage" },
        });
      }
    }
    if (hasCustomGoal.value && totalCustomGoals.value) {
      const customGoalsByDate = getPushNotificationByDay(
        isInvisibleComputed.value ? "custom_goals_accepted" : "custom_goals_seen",
      );
      const customGoalsDenominator = getPushNotificationByDay(
        isInvisibleComputed.value ? "goals" : "uimpressions",
      );
      chartData.push({
        // replace with name
        label: "Custom goal",
        data: Object.fromEntries(customGoalsByDate),
        backgroundColor: colors.value.splice(~~(Math.random() * colors.length), 1)[0],
      });
      const customGoalConversionRate = () => {
        const res = [];
        for (let i = 0; i < customGoalsByDate?.length - 1; i++) {
          res.push({
            key: customGoalsByDate[i][0],
            value: {
              label: percentage(customGoalsByDate[i][1], customGoalsDenominator[i][1]),
              raw: 0,
            },
          });
        }
        return res;
      };
      chartData.push({
        label: "Conv. rate",
        data: customGoalConversionRate(),
        backgroundColor: colors.value.splice(~~(Math.random() * colors.length), 1)[0],
        parsing: { xAxisKey: "key", yAxisKey: "value.raw" },
        props: { type: "percentage" },
      });
    }
  }
  return chartData;
});
const hasData = computed(
  () =>
    !!metricsFormatted.value?.find(
      metricType =>
        !!metricType &&
        !!Object.values(metricType.data || {}).find(
          el => !!(typeof el === "object" ? el.label : el),
        ),
    ),
);
watchEffect(() => {
  if (!props.skeleton && hasData.value) {
    stop.value = onClickOutside(statsButtonsContainer.value, () => (selectedChartKey.value = null));
    useBarChart(
      chart,
      selectedChartKey.value
        ? [metricsFormatted.value[selectedChartKey.value - 1]]
        : metricsFormatted.value,
    );
  } else {
    stop.value?.();
  }
});
onUnmounted(() => stop.value?.());
</script> -->

<script setup>
import RfStatsCard from "@/components/cards/RfStatsCard.vue";
import NoChartDataPlaceholder from "@/components/placeholder/NoChartDataPlaceholder.vue";
import RfBaseSkeleton from "@/components/skeletons/RfBaseSkeleton.vue";
import { METRICS_PERIODS } from "@/utils/constants/MetricsConstants";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import { computed, ref } from "vue";

const props = defineProps({
  metrics: { type: Object, default: () => ({}) },
});

const skeleton = ref(true);
const selectedChartKey = ref(null);

const selectChartKey = key => {
  if (key === selectedChartKey.value) return (selectedChartKey.value = null);
  return (selectedChartKey.value = key);
};
const metricsFormatted = computed(() => {
  if (Object.keys(props.metrics).length === 0)
    return [{ data: { asd: "asd" } }, { data: { asd: "asd" } }];
  return [{ data: { asd: "asd" } }, { data: { asd: "asd" } }];
});

const hasData = computed(
  () =>
    !!metricsFormatted.value?.find(
      metricType =>
        !!metricType &&
        !!Object.values(metricType.data || {}).find(
          el => !!(typeof el === "object" ? el.label : el),
        ),
    ),
);

const singleStatByDefault = computed(() => metricsFormatted.value?.length > 1);
</script>

<style scoped lang="scss">
.rf-prompt-chart--chart {
  height: 249px;
}
</style>
