import { toValue } from "@vueuse/core";
import {
  Chart,
  BarController,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from "chart.js";
import dayjs from "dayjs";
import { ref } from "vue";
import { dateOrder } from "../DateDisplayUtils";

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = chart.canvas.parentNode.parentNode.querySelector(
    "div.rf-prompt-data--legend-container",
  );
  if (!legendContainer) return null;
  let listContainer = legendContainer.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "flex";
    listContainer.style.flexDirection = "row";
    listContainer.style.justifyContent = "flex-end";
    listContainer.style.gap = "24px";
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  chart.canvas.parentNode.parentNode.appendChild(legendContainer);

  return listContainer;
};

const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    if (!ul) return null;
    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach(item => {
      const li = document.createElement("li");
      li.style.alignItems = "center";
      li.style.display = "flex";
      li.style.flexDirection = "row";
      li.style.gap = "8px";

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + "px";
      boxSpan.style.display = "inline-block";
      boxSpan.style.flexShrink = 0;
      boxSpan.style.height = "20px";
      boxSpan.style.width = "20px";
      boxSpan.style.borderRadius = "4px";

      // Text
      const textContainer = document.createElement("p");
      textContainer.style.color = "#212121";
      textContainer.style.fontFamily = "Roboto";
      textContainer.style.fontSize = "14px";
      textContainer.style.lineHeight = "20px";
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? "line-through" : "";

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

const getOrCreateTooltip = chart => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div#tooltip");

  if (tooltipEl) return tooltipEl;

  tooltipEl = document.createElement("div");
  tooltipEl.setAttribute("id", "tooltip");
  tooltipEl.style.background = "#ffffff";
  tooltipEl.style.borderRadius = "4px";
  tooltipEl.style.color = "#212121";
  tooltipEl.style.fontFamily = "Roboto";
  tooltipEl.style.fontSize = "16px";
  tooltipEl.style.lineHeight = "24px";
  tooltipEl.style.padding = "16px 32px";
  tooltipEl.style.opacity = 1;
  tooltipEl.style.pointerEvents = "none";
  tooltipEl.style.position = "absolute";
  tooltipEl.style.transform = "translate(-50%, 0)";
  tooltipEl.style.transition = "all .1s ease";
  tooltipEl.style.boxShadow = "0px 4px 30px 0px rgba(59, 80, 117, 0.20)";

  const table = document.createElement("table");
  table.style.margin = "0px";
  table.style.position = "relative";

  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("width", 17);
  svg.setAttribute("height", 15);
  svg.setAttribute("fill", "none");
  svg.setAttribute("viewBox", "0 0 17 15");
  svg.style.position = "absolute";
  svg.style.bottom = "-12px";
  svg.style.left = "50%";
  svg.style.zIndex = "-1";
  svg.style.transform = "translate(-50%, 0)";
  const arrow = document.createElementNS("http://www.w3.org/2000/svg", "path");
  arrow.setAttribute("d", "M8.5 15L0.272758 0.75L16.7272 0.750002L8.5 15Z");
  arrow.style.fill = "#ffffff";

  svg.appendChild(arrow);
  tooltipEl.appendChild(svg);
  tooltipEl.appendChild(table);
  chart.canvas.parentNode.appendChild(tooltipEl);

  return tooltipEl;
};

const externalTooltipHandler = ({ chart, tooltip }) => {
  // Tooltip Element
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map(b => b.lines);
    const tableBody = document.createElement("tbody");
    bodyLines.forEach(body => {
      const [titleValue, bodyValue] = body[0].split(":");
      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;

      const text = document.createTextNode(body);
      const data = document.createElement("span");
      data.style.borderWidth = 0;

      const title = document.createElement("span");
      title.style.borderWidth = 0;
      title.style.color = "#252C38";
      title.style.fontWeight = "400";
      title.innerText = `${titleValue}:`;

      const value = document.createElement("span");
      value.style.borderWidth = 0;
      value.style.fontWeight = "600";
      if (tooltip.dataPoints?.[0]?.dataset?.props?.type === "percentage") {
        const percentageValue =
          tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex].value.label;
        value.innerText = ` ${percentageValue}%`;
      } else {
        value.innerText = bodyValue;
      }

      data.appendChild(title);
      data.appendChild(value);
      td.appendChild(data);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
  tooltipEl.style.top = `${positionY + tooltip.caretY - 70}px`;
  tooltipEl.style.font = tooltip.options.bodyFont.string;
};

export const useBarChart = (canvasRef, datasets = []) => {
  const canvas = toValue(canvasRef);
  if (!canvas) return;
  const updates = ref(0);
  Chart.register(BarController, BarElement, Tooltip, Legend, CategoryScale, LinearScale);

  const destroy = () => {
    if (Chart.getChart(toValue(canvas))) Chart.getChart(toValue(canvas)).destroy();
  };

  destroy();
  new Chart(canvas, {
    type: "bar",
    plugins: [
      htmlLegendPlugin,
      {
        id: "percentagePlugin",
        beforeDraw: chart => {
          const indexes = chart.data.datasets.reduce(
            (acc, el, i) => (el?.props?.type === "percentage" ? acc.push(i) && acc : acc),
            [],
          );

          if (!indexes.length) return;
          indexes.map(datasetIndex => {
            const length = chart.data.datasets?.[datasetIndex]?.data?.length || 0;

            for (let i = 0; i < length ? length - 1 : 0; i++) {
              const max = chart.data.datasets.length === 1 ? 1 : chart.scales.y.max;
              chart.data.datasets[datasetIndex].data[i].value.raw = Math.min(
                (max * chart.data.datasets[datasetIndex].data[i].value.label) / 100,
                100,
              );
            }
          });

          // hack in order to render percentage scales
          // TODO: find more clever solution
          updates.value += 1;
          if (updates.value < 3) {
            chart.update();
            chart.clear();
          }
        },
      },
    ],
    options: {
      scales: {
        x: {
          afterFit: a =>
            (a.ticks = a.ticks.map(el => ({
              ...el,
              label: dayjs(el.label).format(el.label.length > 8 ? "hh:mm" : dateOrder()),
            }))),
          ticks: { color: "#57595C", font: { family: "Roboto", size: "16px", lineHeight: "20px" } },
          grid: { display: false },
        },
        y: {
          suggestedMax: 1,
          ticks: { color: "#57595C", font: { family: "Roboto", size: "16px", lineHeight: "20px" } },
          border: { display: false, dash: ({ tick }) => (tick.value > 0 ? [5, 5] : false) },
          grid: {
            color: ({ tick }) => (tick.value > 0 ? "#999999" : "#57595C"),
            lineWidth: ({ tick }) => (tick.value > 0 ? 1 : 2),
            drawTicks: false,
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      datasets: { bar: { stack: false, borderWidth: { bottom: 10 } } },
      animation: false,
      plugins: {
        legend: {
          display: datasets.length < 1,
          align: "end",
          position: "bottom",
          labels: {
            boxHeight: 24,
            boxWidth: 24,
            padding: 24,
            font: { size: 14, family: "Roboto", lineHeight: 20 },
            borderRadius: 4,
            useBorderRadius: true,
          },
        },
        tooltip: { enabled: false, external: externalTooltipHandler },
        htmlLegend: { containerID: "legend-container" },
      },
    },
    data: {
      datasets: datasets.map(el => ({ ...el, borderWidth: 0 })),
    },
  });

  return destroy;
};
