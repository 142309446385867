<template>
  <div>
    <div class="all-experiments-div-header">
      <h4>Experiments</h4>
      <div class="all-experiments-button-div" v-if="!isCurrExperimentRunning && !currExperiment">
        <RfButton
          v-if="experiments.length"
          icon="add"
          button-text="New experiment"
          color="accent"
          class="promo-add-integration"
          :disabled="isDisabledRoleMixin || isLocalized"
          @click.stop="newExperimentDialog = true"
        />
      </div>
    </div>
    <div v-if="experiments.length">
      <v-simple-table class="all-experiments-table">
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Created date</th>
            <th class="text-left">Variations</th>
            <th class="text-left">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(experiment, index) in experiments" :key="index">
            <td class="col-exp-2">
              <div class="list-experiment-name" @click="$emit('showItem', experiment)">
                {{ experiment.name }}
              </div>
            </td>
            <td>{{ $moment(new Date(experiment.created_at)).format(`${dateOrder()}/YYYY`) }}</td>
            <td>
              {{ experiment.variations.filter(item => !item.is_control).length }}
            </td>
            <td>
              <span v-if="experiment.is_completed && !experiment.is_active">Completed</span>
              <span v-if="!experiment.is_completed && experiment.is_active">
                <v-icon left color="success" size="14">fiber_manual_record</v-icon>Running
              </span>
              <span v-if="!experiment.is_completed && !experiment.is_active">
                <v-icon left color="cccccc" size="14">fiber_manual_record</v-icon>Not Started
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div class="empty-experiment-div" v-else>
      <span style="font-size: 1.3em; font-weight: 500; padding: 30px 0; display: block"
        >Nothing to show.</span
      >
      <RfButton
        icon="add"
        button-text="Create an experiment"
        x-large
        color="success"
        class="promo-add-integration mt-10"
        :disabled="isDisabledRoleMixin || isLocalized"
        @click.stop="newExperimentDialog = true"
      />
    </div>
    <RfPromoCreateExperimentModal
      v-on:closeCreateDialog="closeCreateDialog"
      :newExperimentDialog="newExperimentDialog"
      :path="path"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import RfPromoCreateExperimentModal from "@/components/RfPromotions/RfPromoCreateExperimentModal.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin";
import { cloneDeep } from "lodash-es";
import { isLocalized } from "@/utils/prompts/promptHelpers";
import { computed } from "vue";
import { dateOrder } from "@/utils/DateDisplayUtils";

export default {
  name: "RfAbTestingTable",
  props: ["experiments", "path"],
  components: { RfPromoCreateExperimentModal, RfButton },
  mixins: [RoleMixin],
  setup: props => ({ isLocalized: computed(() => isLocalized(props.path)) }),
  data() {
    return {
      newExperimentDialog: false,
    };
  },
  computed: {
    ...mapState({
      experiment: state => state.tmp.experiment,
      currExperiment: state => cloneDeep(state.apps.currExperiment),
    }),
    ...mapGetters(["isCurrExperimentRunning"]),
  },
  methods: {
    openCreateModal() {
      this.newExperimentDialog = true;
    },
    closeCreateDialog() {
      this.newExperimentDialog = false;
    },
  },
  watch: {},
};
</script>
