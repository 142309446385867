<template>
  <div class="flex flex-col gap-3 rounded bg-white-1 p-5">
    <div>
      <a name="details"><h2 class="text-heading-1 !text-black-1">Details</h2> </a>
    </div>

    <div class="flex flex-col gap-x-8 gap-y-6 lg:flex-row">
      <div class="lg:rf-prompt-details-img rf-prompt-details-img--small-screen flex flex-col gap-3">
        <div
          ref="imagePreviewRef"
          class="group/thumb rf-details--thumbnail relative origin-bottom-left translate-x-0 translate-y-0 rotate-0 skew-x-0 skew-y-0 overflow-hidden !rounded"
          :class="{ 'lg:scale-200 lg:!rounded-none': imagePreview }"
        >
          <RfPromotionThumbnail
            v-if="props.prompt?.actions"
            class="h-full w-full"
            :item="props.prompt"
          />
          <button
            v-if="!props.isInvisible && !props.disabled"
            class="absolute inset-0 flex bg-black-3 bg-opacity-0 group-hover/thumb:bg-opacity-60"
            :class="{ 'opacity-0': imagePreview }"
            @click="clickPreview"
          >
            <span
              class="m-auto inline-flex items-center gap-1 opacity-0 group-hover/thumb:opacity-100"
            >
              <RfEyeOpenIcon class="!h-6 !w-6 !fill-white-1" />
              <span class="text-body !text-white-1"> Preview </span>
            </span>
          </button>
        </div>
        <div class="inline-flex !aspect-auto gap-2">
          <RfButtonNew
            type="secondary"
            :disabled="
              props.disabled ||
              (props.isInvisible && 'Creative editor is not available for invisible prompt')
            "
            class="inline-grid w-full place-items-center p-2"
            :to="creativeLink"
          >
            <RfEditIcon class="!h-6 !w-6" />
            Edit prompt design
          </RfButtonNew>
        </div>
      </div>
      <div class="flex flex-col">
        <RfTable
          v-if="props.prompt?.filter"
          class="w-full overflow-auto"
          :tableData="{ totalCount: 4, loadMore: 4 }"
          hideHeader
        >
          <template #tbody>
            <template v-for="row in detailsRow">
              <tr
                v-if="row.showIf"
                :key="row.name"
                class="h-full border-t border-solid border-strokes first:border-t-0"
              >
                <td class="text-heading-2 h-px w-0 min-w-40 py-2 pl-0 !text-black-2">
                  <div class="flex h-full w-full items-start">
                    <div class="flex h-7 items-center">
                      {{ row.name }}
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <component
                    v-if="row.component"
                    :is="row.component"
                    v-bind="row.props"
                    v-on="row.listeners"
                  />
                </td>
                <td class="w-0 py-2">
                  <RfButtonNew
                    v-if="row.event"
                    size="none"
                    type="none"
                    :disabled="
                      props.disabled || (!!row.props.inherited && 'Disabled due to inheritance')
                    "
                    class="relative h-6 w-6 before:absolute before:-inset-2"
                    @click="emit(row.event)"
                  >
                    <RfEditIcon class="!h-6 !w-6" />
                  </RfButtonNew>
                </td>
              </tr>
            </template>
          </template>
        </RfTable>
      </div>
    </div>
  </div>
</template>

<script setup>
import RfPromotionThumbnail from "@/components/RfPromotions/RfPromotionThumbnail.vue";
import RfTable from "@/blocks/RfTable/RfTable.vue";
import { computed, ref } from "vue";
import RfEditIcon from "@/components/icons/RfEditIcon.vue";
import { getGuideLink, getSegmentLink, getZoneLink } from "@/utils/getLink";
import RfPromptDetailsSegments from "./RfPromptDetailsSegments.vue";
import RfPromptDetailsTriggers from "./RfPromptDetailsTriggers.vue";
import RfPromptDetailsLimits from "./RfPromptDetailsLimits.vue";
import { percentage } from "@/utils/metricsHelpers";
import RfPromptDetailsSchedule from "./RfPromptDetailsSchedule.vue";
import RfEyeOpenIcon from "@/components/icons/RfEyeOpenIcon.vue";
import { usePreviewToggle } from "@/utils/composables/usePreviewToggle ";
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import RfPromptDetailsZone from "./RfPromptDetailsZone.vue";
import RfPromptDetailsInterval from "./RfPromptDetailsInterval.vue";
import { isStandalone } from "@/utils/prompts/promptHelpers";
import RfPromptDetailsTags from "./RfPromptDetailsTags.vue";

const emit = defineEmits([
  "editSegments",
  "editTriggers",
  "editLimits",
  "editSchedule",
  "edit",
  "createTag",
  "removeTag",
  "assignTag",
]);

const props = defineProps({
  prompt: { type: Object, default: () => ({}) },
  segments: { type: Array, default: () => [] },
  appTags: { type: Array, default: () => [] },
  promptTags: { type: Array, default: () => [] },
  appId: { type: String, default: "" },
  creativeLink: { type: String, default: null },
  sequence: { type: Object, default: null },
  triggers: { type: Array, default: () => [] },
  disabled: { type: Boolean, default: false },
  isInvisible: { type: Boolean, default: false },
  isPushNotification: { type: Boolean, default: false },
});

const imagePreviewRef = ref(null);
const imagePreview = ref(false);
const clickPreview = usePreviewToggle(imagePreviewRef, imagePreview);
const hasZone = computed(() => !isStandalone(props.prompt));

const detailsRow = computed(() => {
  return [
    {
      name: "Zone",
      component: RfPromptDetailsZone,
      showIf: hasZone.value,
      props: {
        zone: {
          name: props.prompt.path_group.name,
          link: getZoneLink(props.prompt.path_group.id, props.appId),
        },
      },
    },
    {
      name: "Segments",
      event: "editSegments",
      component: RfPromptDetailsSegments,
      showIf: true,
      props: {
        links: props.segments.map(seg => ({
          name: seg.name,
          link: getSegmentLink(seg.id, props.appId),
        })),
        ...(props.sequence && {
          inherited: {
            name: props.sequence.sequence_name,
            link: getGuideLink(props.sequence.sequence_id, props.appId),
          },
        }),
      },
    },
    {
      name: "Tags",
      component: RfPromptDetailsTags,
      showIf: true,
      props: {
        appTags: props.appTags,
        appId: props.appId,
        promptTags: props.promptTags,
        disabled: props.disabled,
      },
      listeners: {
        createTag: tag => emit("createTag", tag),
        removeTag: tag => emit("removeTag", tag),
        assignTag: tag => emit("assignTag", tag),
      },
    },
    {
      name: "Triggers",
      event: "editTriggers",
      showIf: !hasZone.value,
      component: RfPromptDetailsTriggers,
      props: { triggers: props.triggers },
    },
    {
      name: "Limits",
      event: "editLimits",
      component: RfPromptDetailsLimits,
      showIf: !props.isPushNotification,
      props: {
        limits: {
          limit: {
            if: props.prompt.filter.traffic.is_limited,
            curr: props.prompt.filter.traffic.limit - (props.prompt.curr_limit || 0),
          },
          cap: {
            if: props.prompt.filter.frequency_cap.is_capped,
            value: props.prompt.filter.frequency_cap.cap,
            days: Math.floor(props.prompt.filter.frequency_cap.cap_seconds / (3600 * 24)),
          },
          budget: {
            if: props.prompt.filter.supply.is_budgeted,
            curr: props.prompt.filter.supply.budget - (props.prompt.curr_budget || 0),
            percent:
              100 -
              percentage(
                parseInt(props.prompt.curr_budget),
                parseInt(props.prompt.filter.supply.budget),
              ),
          },
          user: {
            if: props.prompt.filter.users.is_limited,
            curr: props.prompt.filter.users.limit - (props.prompt.curr_users || 0),
          },
          delivery: {
            if: props.prompt.filter.deliveries.is_limited,
            curr: props.prompt.filter.deliveries.limit - (props.prompt.curr_deliveries || 0),
          },
        },
        ...(props.sequence && {
          inherited: {
            name: props.sequence.sequence_name,
            link: getGuideLink(props.sequence.sequence_id, props.appId),
          },
        }),
      },
    },
    {
      name: "Schedule",
      event: "editSchedule",
      component: RfPromptDetailsSchedule,
      showIf: true,
      props: {
        schedule: {
          startDate: props.prompt.start_date,
          endDate: props.prompt.end_date,
          daypart: props.prompt.filter.schedule,
        },
        ...(props.sequence && {
          inherited: {
            name: props.sequence.sequence_name,
            link: getGuideLink(props.sequence.sequence_id, props.appId),
          },
        }),
      },
    },
    {
      name: "Interval",
      event: "editInterval",
      component: RfPromptDetailsInterval,
      showIf: props.isInvisible,
      props: {
        display: props.prompt.actions.rf_settings_permanent_display === "true",
        interval: props.prompt.actions.rf_settings_accept_interval,
      },
    },
  ];
});
</script>

<style scoped>
.rf-details--thumbnail {
  transition: transform 300ms cubic-bezier(0.5, 1.2, 0.5, 1.2);
}
</style>

<style>
.rf-details--thumbnail.lg\:scale-200 {
  .promo-preview-image {
    > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      object-fit: contain;
      max-height: 100%;
    }
  }
}
</style>
