import { percentage, tally } from "@/utils/metricsHelpers";
import { saveToCsvFile } from "@/utils/CsvUtils";

export const DISPLAYED_METRICS = {
  "goals": "Accept",
  "goals2": "Secondary Accept",
  "dismissals": "Dismiss",
  "declines": "Decline",
  "timeouts": "Timeout",
  "unique-endpoints-grouped-by-date": "Endpoints",
  "attempted-deliveries-grouped-by-date": "Attempted Deliveries",
  "unique-deliveries-grouped-by-date": "Deliveries",
};

export const CUSTOM_DISPLAYED_METRICS = {
  custom_goals_accepted: "after Accept",
  custom_goals_accepted2: "after Secondary Accept",
  custom_goals_declined: "after Decline",
  custom_goals_dismissed: "after Dismiss/Timeout",
};

const onlyDisplayed =
  displayed =>
  ([key, _]) =>
    Object.keys(displayed).includes(key);
const namingMapper = displayed => key => displayed[key] || key;

export default {
  computed: {
    onlyDisplayedMetrics() {
      return onlyDisplayed(this.DISPLAYED_METRICS);
    },
    namingMapperMetrics() {
      return namingMapper(this.DISPLAYED_METRICS);
    },
    onlyDisplayedMetricsCustom() {
      return onlyDisplayed(this.CUSTOM_DISPLAYED_METRICS);
    },
    namingMapperMetricsCustom() {
      return namingMapper(this.CUSTOM_DISPLAYED_METRICS);
    },
    DISPLAYED_METRICS() {
      return {
        "goals": this.getDisplayMetricsText("rf_retention_button1_text") || DISPLAYED_METRICS.goals,
        "goals2":
          this.getDisplayMetricsText("rf_retention_button2_text") || DISPLAYED_METRICS.goals2,
        "declines":
          this.getDisplayMetricsText("rf_retention_button3_text") || DISPLAYED_METRICS.declines,
        "dismissals": DISPLAYED_METRICS.dismissals,
        "timeouts": DISPLAYED_METRICS.timeouts,
        "unique-endpoints-grouped-by-date": DISPLAYED_METRICS["unique-endpoints-grouped-by-date"],
        "attempted-deliveries-grouped-by-date":
          DISPLAYED_METRICS["attempted-deliveries-grouped-by-date"],
        "unique-deliveries-grouped-by-date": DISPLAYED_METRICS["unique-deliveries-grouped-by-date"],
      };
    },
    CUSTOM_DISPLAYED_METRICS() {
      return {
        custom_goals_accepted: `after ${this.DISPLAYED_METRICS.goals}`,
        custom_goals_declined: `after ${this.DISPLAYED_METRICS.declines}`,
        custom_goals_dismissed: CUSTOM_DISPLAYED_METRICS.custom_goals_dismissed,
      };
    },
  },
  methods: {
    getDisplayMetricsText(key) {
      const model = this.model || this.path;
      if (
        model?.actions?.rf_settings_tile_interaction === "container" &&
        key === "rf_retention_button1_text"
      )
        return "Click";
      if (!model?.filter?.is_translated) return model.actions?.[key];
      return (
        Object.values(model?.parsed_translations || {}).find(el => el?.default === "TRUE")?.[key] ||
        "Click"
      );
    },
    exportPipelineToCsv(pipeline, currChartSrc) {
      const contents = [];
      pipeline.pipeline_stages.forEach(stage => {
        const row = [`"${stage.name}"`];
        stage.segments.forEach(segment => {
          const count = this.segmentUsersCount(stage.id, segment.id);
          row.push(`"${segment.name}"`, count);
        });
        contents.push(row);
      });
      const header = [
        "stage",
        "trending up",
        "trending up count",
        "trending down",
        "trending down count",
      ];

      contents.unshift(header);
      contents.push("\u2028", "\u2028", "stage transitions");

      const contents2 = [];
      pipeline.pipeline_stages.forEach(fromStage => {
        pipeline.pipeline_stages.forEach(toStage => {
          const promos = this.getTransitionPromos(fromStage.id, toStage.id);
          if (promos.length > 0) {
            promos.forEach(item => {
              const users = tally(item.data, currChartSrc, "users");
              const goals = tally(item.data, currChartSrc, "goals");
              const convRate = percentage(goals, users, true);
              contents.push([fromStage.name, toStage.name, users, goals, convRate]);
            });
          }
        });
      });

      const header2 = ["from stage", "to stage", "users", "goals", "conv. rate"];
      contents2.unshift(header2);

      saveToCsvFile([...contents, ...contents2], `pipeline_${currChartSrc}`);
    },
  },
};
