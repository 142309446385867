<template>
  <div class="schedule-date-selector-wrapper !flex-col">
    <v-container>
      <v-row justify="space-around">
        <div class="date-selector" style="width: 220px">
          <div class="date-selector-text">Start date:</div>
          <div class="date-selector-datepicker date-selector-start" style="margin: 0">
            <v-menu
              v-model="menuStartDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              :disabled="disabled"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="startDateRender"
                  :append-icon="showClearIcon ? undefined : 'event'"
                  readonly
                  v-on="on"
                  :disabled="disabled"
                  outlined
                  dense
                  hide-details
                  background-color="#ffffff"
                  clear-icon="mdi-close-circle"
                  :clearable="showClearIcon"
                  @click:clear="clearStartDate"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title
                v-model="startDate"
                @input="menuStartDate = false"
                :max="endDate"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
        <div class="date-selector" style="width: 220px">
          <div class="date-selector-text">End date:</div>
          <div class="date-selector-datepicker date-selector-end" style="margin: 0">
            <v-menu
              v-model="menuEndDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              :disabled="disabled"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="endDateRender"
                  :append-icon="showClearIcon ? undefined : 'event'"
                  readonly
                  v-on="on"
                  outlined
                  dense
                  hide-details
                  :disabled="disabled"
                  clear-icon="mdi-close-circle"
                  :clearable="showClearIcon"
                  @click:clear="clearEndDate"
                  background-color="#ffffff"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title
                v-model="endDate"
                :disabled="disabled"
                @input="menuEndDate = false"
                :min="startDate"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
      </v-row>
      <v-row justify="space-around" class="mt-5">
        <div class="date-selector" style="width: 220px">
          <div class="date-selector-text">Start time:</div>
          <div class="date-selector-datepicker">
            <RfTimePicker
              @updateTime="updateStartTime"
              :initTime="startTime"
              ref="startTime"
              :disabled="disabled"
              dense
            />
          </div>
        </div>
        <div class="date-selector" style="width: 220px">
          <div class="date-selector-text">End time:</div>
          <div class="date-selector-datepicker">
            <RfTimePicker
              @updateTime="updateEndTime"
              :initTime="endTime"
              ref="endTime"
              dense
              :disabled="disabled"
            />
          </div>
        </div>
      </v-row>
    </v-container>
    <div v-if="isPushNotification" class="ml-12 mt-5 flex flex-col self-start">
      <v-select
        v-if="!hideFrequency"
        class="mb-2"
        background-color="#ffffff"
        outlined
        dense
        hide-details
        :items="[
          { value: 'ONCE', text: 'Once' },
          { value: 'HOURLY', text: 'Hourly' },
          { value: 'DAILY', text: 'Daily' },
          { value: 'WEEKLY', text: 'Weekly' },
          { value: 'MONTHLY', text: 'Monthly' },
        ]"
        label="Frequency"
        v-model.trim="frequency"
      />
      <v-checkbox
        v-model="localTime"
        label="Use local time"
        class="mt-0 pt-0"
        hide-details
        true-value="true"
        false-value="false"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RfTimePicker from "@/components/RfTimePicker.vue";
import StringUtils from "../utils/StringUtils";

export default {
  name: "RfStartStopDatePicker",
  props: {
    dateRange: Object,
    showClearIcon: Boolean,
    disabled: Boolean,
    actions: Object,
    isPushNotification: { type: Boolean, default: false },
    hideFrequency: { type: Boolean, default: false },
  },
  components: { RfTimePicker },
  data() {
    return {
      startDate: null,
      endDate: null,
      startTime: 0,
      endTime: 48,
      menuStartDate: false,
      menuEndDate: false,
      frequency: this.actions?.rf_pinpoint_campaign_frequency || null,
      localTime: this.actions?.rf_pinpoint_campaign_local_time || null,
    };
  },
  created() {
    const { startDate, startTime, endDate, endTime } = this.initDates();
    this.startDate = startDate;
    this.startTime = startTime;
    this.endDate = endDate;
    this.endTime = endTime;
  },
  computed: {
    startDateRender: {
      get() {
        if (this.startDate) return moment.utc(this.startDate).format("MMM Do, YYYY");
      },
      set() {},
    },
    endDateRender: {
      get() {
        if (this.endDate) return moment.utc(this.endDate).format("MMM Do, YYYY");
      },
      set() {},
    },
    fullStartDate() {
      return moment.utc(
        `${this.startDate} ${StringUtils.hhmmBy30MinIntervalIndex(this.startTime)}`,
        "YYYY-MM-DD HH:mm",
      );
    },
    fullEndDate() {
      return moment.utc(
        `${this.endDate} ${StringUtils.hhmmBy30MinIntervalIndex(this.endTime)}`,
        "YYYY-MM-DD HH:mm",
      );
    },
  },
  watch: {
    startDate() {
      this.$emit("dateRangeUpdate", { startDate: this.fullStartDate, endDate: this.fullEndDate });
    },
    endDate() {
      this.$emit("dateRangeUpdate", { startDate: this.fullStartDate, endDate: this.fullEndDate });
    },
    startTime() {
      this.$emit("dateRangeUpdate", { startDate: this.fullStartDate, endDate: this.fullEndDate });
    },
    endTime() {
      this.$emit("dateRangeUpdate", { startDate: this.fullStartDate, endDate: this.fullEndDate });
    },
    dateRange: {
      handler() {
        const { startDate, startTime, endDate, endTime } = this.initDates();
        this.startDate = startDate;
        this.startTime = startTime;
        this.endDate = endDate;
        this.endTime = endTime;
      },
      deep: true,
    },
  },
  methods: {
    clearStartDate() {
      this.startDate = null;
    },
    clearEndDate() {
      this.endDate = null;
    },
    initDates() {
      const initialStartDate = moment.utc(this.dateRange.startDate);
      const initialEndDate = moment.utc(this.dateRange.endDate);

      return {
        startDate: initialStartDate ? initialStartDate.format("YYYY-MM-DD") : null,
        startTime: initialStartDate
          ? StringUtils.intervalIndexByHhmm(initialStartDate.format("HH:mm"))
          : 0,
        endDate: initialEndDate ? initialEndDate.format("YYYY-MM-DD") : null,
        endTime: initialEndDate
          ? StringUtils.intervalIndexByHhmm(initialEndDate.format("HH:mm"))
          : 48,
      };
    },
    updateStartTime(time) {
      this.startTime = time;
    },
    updateEndTime(time) {
      this.endTime = time;
    },
  },
};
</script>
