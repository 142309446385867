<template>
  <span class="flex items-center justify-center">
    <button
      v-if="!props.anchor"
      ref="tooltipActivatorEl"
      class="flex flex-grow-0 items-center"
      @focus="showTooltip"
      @mouseenter="showTooltip"
      @blur="hideTooltip"
      @mouseleave="hideTooltip"
      @click="modalOpened ? hideTooltip() : showTooltip()"
    >
      <slot name="activator" :opened="modalOpened"></slot>
    </button>
    <Transition>
      <div
        ref="tooltipEl"
        v-if="modalOpened"
        class="rf-tooltip text-body bg-white-1 absolute left-0 top-0 z-50 w-max items-start whitespace-pre-line rounded px-5 py-4 text-left"
      >
        <slot name="tooltip"></slot>
        <svg
          width="17"
          height="15"
          ref="arrowEl"
          class="rf-tooltip--arrow fill-white-1 absolute"
          viewBox="0 0 17 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8.5 15L0.272758 0.75L16.7272 0.750002L8.5 15Z" />
        </svg>
      </div>
    </Transition>
  </span>
</template>

<script setup>
import { computePosition, flip, offset, arrow, shift, inline } from "@floating-ui/dom";
import { nextTick, ref } from "vue";

const props = defineProps({
  preset: { type: String, default: null },
  position: { type: String, default: null },
  anchor: { type: HTMLElement, default: null },
});

const modalOpened = ref(false);
const tooltipEl = ref();
const arrowEl = ref();
const tooltipActivatorEl = ref();

const presets = {
  default: {
    placement: props.position || "top",
    middleware: [offset(6), shift({ padding: 5 }), flip()],
  },
};

const tooltipOffset = {
  top: { x: 0, y: -10 },
  bottom: { x: 0, y: 10 },
  right: { x: 10, y: 0 },
  left: { x: -10, y: 0 },
};

const staticSide = {
  top: "bottom",
  right: "left",
  bottom: "top",
  left: "right",
};

const rotateDegree = {
  top: "0",
  right: "90",
  bottom: "180",
  left: "-90",
};

const update = () =>
  nextTick(() => {
    const buttonValue = props.anchor || tooltipActivatorEl.value;
    const tooltipValue = tooltipEl.value;

    const preset = presets[props.preset] || presets.default;
    computePosition(buttonValue, tooltipValue, {
      ...preset,
      middleware: [arrow({ element: arrowEl.value }), ...preset.middleware],
    }).then(({ x, y, placement, middlewareData, ...strategy }) => {
      const direction = placement.split("-")[0];
      Object.assign(tooltipValue.style, {
        transform: `translate(${x + tooltipOffset[direction].x}px, ${
          y + tooltipOffset[direction].y
        }px)`,
      });
      if (middlewareData.arrow) {
        const { x: arrowX, y: arrowY } = middlewareData.arrow;

        Object.assign(arrowEl.value.style, {
          left: arrowX != null ? `${arrowX - middlewareData?.shift?.x || 0}px` : "unset",
          top: arrowY != null ? `${arrowY - middlewareData?.shift?.y || 0}px` : "unset",
          right: "unset",
          bottom: "unset",
          [staticSide[direction]]: "-12px",
          transform: `rotate(${rotateDegree[direction]}deg)`,
        });
      }
    });
  });

const showTooltip = () => {
  modalOpened.value = true;
  update();
};
const hideTooltip = () => (modalOpened.value = false);

defineExpose({ show: showTooltip, hide: hideTooltip });
</script>

<style lang="scss" scoped>
.rf-tooltip {
  box-shadow: 0px 4px 27px rgba(117, 130, 153, 0.25);
  &--arrow {
    z-index: -1;
  }
}
</style>
