<template>
  <div class="flex flex-col gap-4">
    <div class="flex items-start justify-between gap-x-16">
      <div class="flex flex-col gap-1">
        <div class="flex items-center gap-4">
          <button class="grid place-items-center" @click="emit('back')">
            <RfBackIcon class="!h-6 !w-6 !stroke-black-2" />
          </button>
          <h2 class="text-heading-0 !font-medium !text-black-1">
            {{ props.name || "Unnamed prompt" }}
          </h2>
          <RfButtonNew
            type="none"
            size="none"
            class="grid place-items-center"
            :disabled="props.disabled"
            @click="emit('edit')"
          >
            <RfEditIcon class="!h-6 !w-6" />
          </RfButtonNew>
        </div>
        <span class="text-body ml-10">{{ props.description }}</span>
      </div>

      <div class="inline-grid flex-shrink-0 grid-cols-1 items-center gap-5">
        <RfMenu
          :disabled="props.disabled"
          sameWidth
          buttonSize="base"
          buttonType="main"
          class="rf-submenu z-10"
        >
          <template #activator>
            <span class="inline-flex items-center gap-2">
              <span class="text-action-buttons !text-white-1"> Manage </span>
            </span>
          </template>
          <template #menu>
            <div class="list flex w-full flex-col items-start">
              <template
                v-for="action in [
                  {
                    if: props.showReset,
                    icon: RfResetIcon,
                    title: 'Reset',
                    onClick: () => emit('reset'),
                  },
                  {
                    if: props.showClone,
                    icon: RfCloneIcon,
                    title: 'Clone',
                    onClick: () => emit('clone'),
                  },
                  {
                    if: props.showPriority,
                    icon: RfPriorityIcon,
                    title: 'Priority',
                    onClick: () => emit('priority'),
                  },
                  { icon: RfDownloadIcon, title: 'Export to CSV', onClick: () => emit('export') },
                  {
                    if: !props.disabled,
                    icon: RfDeleteIcon,
                    title: 'Delete',
                    onClick: () => emit('delete'),
                  },
                ]"
              >
                <button
                  v-if="action?.if ?? true"
                  class="text-action-buttons flex w-full items-center gap-2 px-4 py-3 first:!pt-6 last:!pb-6"
                  @click="action.onClick"
                >
                  <component :is="action.icon" class="!h-6 !w-6" /> {{ action.title }}
                </button>
              </template>
            </div>
          </template>
        </RfMenu>
      </div>
    </div>
    <div class="grid auto-cols-max grid-cols-3 gap-4 xl:grid-cols-prompt-header">
      <div class="inline-flex items-center justify-start">
        <RfMenu
          v-if="!statusDisabled"
          class="rf-status-menu"
          sameWidth
          minFit
          buttonType="white"
          :disabled="props.status.type === SimplifyStatusesMapConstants.limited.type"
        >
          <template #activator>
            <span class="inline-flex items-center gap-1">
              <span class="inline-flex !h-6 !w-6">
                <RfStatus class="m-auto" :status="props.status.type" />
              </span>
              <span class="text-body inline-block">
                {{ StatusesConstants[props.status.type].title }}
              </span>
            </span>
          </template>
          <template #menu>
            <template v-for="status in StatusesSimplifiedConstants">
              <button
                v-if="
                  status.type === SimplifyStatusesMapConstants.ready.type
                    ? !props.isEnabled
                    : ![
                        SimplifyStatusesMapConstants[props.status.type].type,
                        SimplifyStatusesMapConstants.limited.type,
                      ].includes(status.type)
                "
                :key="status.type"
                class="flex w-full items-center gap-3 px-4 py-2 first:!pt-4 last:!pb-4"
                @click="$emit('update:status', status.type)"
              >
                <RfStatus :status="status.type" />
                {{ status.action }}
              </button>
            </template>
          </template>
        </RfMenu>
      </div>
      <div
        class="text-body xl:order-0 -order-1 col-span-3 flex items-center justify-start gap-2 xl:col-span-1"
      >
        <span class="text-heading-2 !text-black-2">Info:</span>
        <button class="inline-flex items-center gap-1 py-1 pl-3 pr-2" @click="$emit('copyId')">
          <span class="text-left">ID: {{ props.id }}</span>
          <RfCopyIcon class="!h-6 !w-6" />
        </button>
        <span class="h-6 w-px bg-icon"></span>
        <component
          :is="!disabled && customDevicesEnabled ? 'button' : 'span'"
          class="inline-flex items-center gap-1 py-1 pl-3 pr-2"
          @click="$emit('editCustomDevices')"
        >
          <span>{{ props.type }}</span>
          <RfEditIcon v-if="!disabled && customDevicesEnabled" class="!h-6 !w-6" />
        </component>
        <span class="h-6 w-px bg-icon"></span>
        <span class="py-1 pl-3 pr-2">Created by: {{ props.createdBy || "Redfast" }}</span>
      </div>
      <div class="col-span-2 inline-flex items-center justify-end xl:col-span-1">
        <RfDatePicker
          ref="datePicker"
          :sinceStartDayRef="props.startDate"
          :min="props.oneYearMetrics ? dayjs().subtract(1, 'year') : dayjs().subtract(90, 'days')"
          :max="dayjs()"
          @input="v => emit('update:date', v)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import RfBackIcon from "@/components/icons/RfBackIcon.vue";
import RfCloneIcon from "@/components/icons/RfCloneIcon.vue";
import RfCopyIcon from "@/components/icons/RfCopyIcon.vue";
import RfDeleteIcon from "@/components/icons/RfDeleteIcon.vue";
import RfDownloadIcon from "@/components/icons/RfDownloadIcon.vue";
import RfEditIcon from "@/components/icons/RfEditIcon.vue";
import RfPriorityIcon from "@/components/icons/RfPriorityIcon.vue";
import RfResetIcon from "@/components/icons/RfResetIcon.vue";
import RfDatePicker from "@/components/inputs/RfDatePicker.vue";
import RfMenu from "@/components/menus/RfMenu.vue";
import StatusesConstants, {
  SimplifyStatusesMapConstants,
  StatusesSimplifiedConstants,
} from "@/utils/constants/PromptStatusesConstants";
import dayjs from "dayjs";
import { onMounted, ref } from "vue";
import RfStatus from "../RfPrompts/RfStatus.vue";
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";

const emit = defineEmits([
  "back",
  "edit",
  "reset",
  "clone",
  "priority",
  "export",
  "delete",
  "copyId",
  "update:status",
  "update:date",
]);

const props = defineProps({
  name: { type: String, default: "" },
  description: { type: String, default: "" },
  disabled: { type: Boolean, default: true },
  id: { type: String, default: "none" },
  type: { type: String, default: "none" },
  startDate: { type: String, default: null },
  createdBy: { type: String, default: "" },
  defaultDate: { type: String, default: "this_week" },
  date: { type: Object, default: () => ({}) },
  status: { type: Object, default: () => StatusesConstants.ended },
  statusDisabled: { type: Boolean, default: true },
  showClone: { type: Boolean, default: false },
  showReset: { type: Boolean, default: false },
  showPriority: { type: Boolean, default: false },
  oneYearMetrics: { type: Boolean, default: false },
  isEnabled: { type: Boolean, default: false },
  customDevicesEnabled: { type: Boolean, default: false },
});

const datePicker = ref();

onMounted(() => datePicker.value?.setTimeFromPreset(props.defaultDate || "this_week"));
</script>

<style lang="scss" scoped>
::v-deep.rf-status-menu {
  .rf-menu--button {
    &.disabled {
      @apply pr-3;
    }
  }
  .rf-menu--icon-wrapper {
    > span {
      @apply h-6 w-6 #{!important};
    }
  }
}

::v-deep.rf-submenu {
  .rf-menu--button {
    @apply py-1 pl-12 pr-6;

    &.disabled {
      @apply px-10;
    }
  }
  .rf-menu--icon-wrapper .rf-icon {
    @apply fill-white-1;
  }
}
</style>
