<template>
  <span>
    <span class="rf-button-input relative" data-cy="search">
      <Transition :duration="200" name="button-input" mode="out-in">
        <span v-if="closed" key="button">
          <button
            class="rf-button-input--button text-body flex items-center justify-center"
            @click="open"
          >
            <component
              v-if="props.icon"
              :is="props.icon"
              class="rf-button-input--button-icon !h-6 !w-6"
            />
            {{ text }}
          </button>
        </span>

        <RfInput
          v-else
          key="input"
          :value="props.value"
          ref="inputEl"
          :size="props.size"
          :placeholder="props.placeholder"
          @input="v => emit('input', v)"
        >
          <template #prepend>
            <button
              class="rf-button-input--input-button-icon absolute inset-y-0 right-0 flex items-center justify-center px-4 [&>*]:!h-6 [&>*]:!w-6"
              @click="closed = true"
            >
              <component v-if="props.icon" :is="props.icon" />
            </button> </template
        ></RfInput>
      </Transition>
    </span>
  </span>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import RfInput from "./RfInput.vue";

const props = defineProps({
  text: { type: String, default: "Default" },
  icon: { type: undefined, default: null },
  value: { type: String, default: () => "" },
  placeholder: { type: String, default: "Input" },
  size: { type: String, default: "base", validator: v => ["base"].includes(v) },
});

const emit = defineEmits(["input"]);

const closed = ref(true);
const inputEl = ref();

const open = () => {
  closed.value = false;
  setTimeout(() => inputEl.value?.$el?.focus?.(), 330);
};

watchEffect(() => props.value && open());
</script>

<style lang="scss" scoped>
.button-input-enter-active {
  transition: all 0.2s ease-out;
}

.button-input-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.button-input-enter-from,
.button-input-leave-to {
  opacity: 0;
}

.rf-button-input {
  &--button {
    gap: 9px;
    padding: 8px 12px 8px 17px;
  }
}
</style>
