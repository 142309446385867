<template>
  <div class="bg-white-1 flex flex-col rounded p-5">
    <button class="flex items-center justify-between" @click="opened = !opened">
      <h2 class="text-heading-1 !text-black-1">{{ props.title }}</h2>
      <Transition mode="out-in">
        <RfChevronMiniClosedIcon v-if="!opened" class="my-auto !h-7 !w-7" />
        <RfChevronMiniOpenIcon v-else class="my-auto !h-7 !w-7" />
      </Transition>
    </button>
    <div
      class="grid grid-rows-0fr transition-grid-template-rows"
      :class="{ 'grid-rows-1fr': opened }"
    >
      <div class="overflow-hidden transition-padding duration-500" :class="{ 'pt-3': opened }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import RfChevronMiniClosedIcon from "../icons/RfChevronMiniClosedIcon.vue";
import RfChevronMiniOpenIcon from "../icons/RfChevronMiniOpenIcon.vue";

const props = defineProps({ title: String, initOpenTrue: { type: Boolean, default: false } });
const opened = ref(props.initOpenTrue);
</script>
