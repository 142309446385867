var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"rf-bulk-action-menu bg-white-1 flex items-center gap-2 overflow-hidden rounded-t border-b border-solid border-background",attrs:{"data-cy":"table-select"}},[_c(_setup.RfMenu,{ref:"tableSelect",staticClass:"rf-bulk-action-menu--checkbox",scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(_setup.RfCheckbox,{attrs:{"data-cy":"table-select--all py-1","value":!!(_vm.selected.size && _setup.isAllSelected),"intermediate":!!(_vm.selected.size && !_setup.isAllSelected)},on:{"input":function($event){return _vm.$emit(_setup.isAllSelected ? 'deselectAll' : 'selectAll')}},nativeOn:{"click":function($event){$event.stopPropagation();}}})]},proxy:true},{key:"menu",fn:function(){return [_c('div',{staticClass:"list flex w-36 flex-col gap-4 p-4"},[_c('button',{staticClass:"rounded p-2 text-left",class:{ '!bg-blue-5 !text-blue-1': _setup.isAllSelected },attrs:{"data-cy":"table-select--dropdown-all"},on:{"click":() => (_vm.$emit('selectAll'), _vm.$refs.tableSelect.hide())}},[_vm._v(" All ")]),_c('button',{staticClass:"rounded p-2 text-left",class:{
            '!bg-blue-5 !text-blue-1':
              !!_setup.selectedStatuses[_setup.Statuses.true.type] && !_setup.selectedStatuses[_setup.Statuses.false.type],
          },attrs:{"data-cy":"table-select--dropdown-enabled"},on:{"click":() => (_vm.$emit('selectByPreset', _setup.Statuses.true.type), _vm.$refs.tableSelect.hide())}},[_vm._v(" "+_vm._s(_setup.Statuses.true.title)+" ")]),_c('button',{staticClass:"rounded p-2 text-left",class:{
            '!bg-blue-5 !text-blue-1':
              !_setup.selectedStatuses[_setup.Statuses.true.type] && !!_setup.selectedStatuses[_setup.Statuses.false.type],
          },attrs:{"data-cy":"table-select--dropdown-disabled"},on:{"click":() => (_vm.$emit('selectByPreset', _setup.Statuses.false.type), _vm.$refs.tableSelect.hide())}},[_vm._v(" "+_vm._s(_setup.Statuses.false.title)+" ")])])]},proxy:true}])}),_c('Transition',[(_vm.selected.size)?_c('span',{staticClass:"text-body mr-2 inline-flex items-center gap-2",attrs:{"data-cy":"table-select--actions"}},[(_vm.selected.size)?_c(_setup.RfBaseTag,{staticClass:"!bg-input-background",attrs:{"data-cy":"table-select--selected","data-cy-value":_vm.selected.size},on:{"clear":function($event){return _vm.$emit('deselectAll')}}},[_vm._v(" "+_vm._s(_vm.selected.size)+" selected ")]):_vm._e(),(_vm.selected.size === 1 && !_setup.selectedStatuses.disableStatusChange)?_c(_setup.RfButtonNew,{attrs:{"data-cy":"table-select--edit","type":"table"},on:{"click":function($event){return _vm.$emit('edit')}}},[_c(_setup.RfEditIcon,{staticClass:"!h-6 !w-6"}),_vm._v(" Edit ")],1):_vm._e(),(
          !_setup.selectedStatuses[_setup.Statuses.true.type] &&
          !!_setup.selectedStatuses[_setup.Statuses.false.type] &&
          !_setup.selectedStatuses.disableStatusChange
        )?_c(_setup.RfButtonNew,{attrs:{"data-cy":"table-select--enable","type":"table"},on:{"click":function($event){return _vm.$emit('status', _setup.Statuses.true.type)}}},[_c(_setup.RfPlayIcon,{staticClass:"!h-6 !w-6"}),_vm._v(" Enable ")],1):_vm._e(),(
          !!_setup.selectedStatuses[_setup.Statuses.true.type] &&
          !_setup.selectedStatuses[_setup.Statuses.false.type] &&
          !_setup.selectedStatuses.disableStatusChange
        )?_c(_setup.RfButtonNew,{attrs:{"data-cy":"table-select--disable","type":"table"},on:{"click":function($event){return _vm.$emit('status', _setup.Statuses.false.type)}}},[_c(_setup.RfStopIcon,{staticClass:"!h-6 !w-6"}),_vm._v(" Disable ")],1):_vm._e(),(_vm.selected.size === 1)?_c(_setup.RfButtonNew,{attrs:{"type":"table","data-cy":"table-select--clone"},on:{"click":function($event){return _vm.$emit('clone')}}},[_c(_setup.RfCloneIcon,{staticClass:"!h-6 !w-6"}),_vm._v(" Clone ")],1):_vm._e(),_c(_setup.RfButtonNew,{attrs:{"type":"table"},on:{"click":function($event){return _vm.$emit('export')}}},[_c(_setup.RfDownloadIcon,{staticClass:"!h-6 !w-6"}),_vm._v(" Export to CSV ")],1),(!_setup.selectedStatuses.disableStatusChange)?_c(_setup.RfButtonNew,{attrs:{"data-cy":"table-select--delete","type":"table"},on:{"click":function($event){return _vm.$emit('delete')}}},[_c(_setup.RfDeleteIcon,{staticClass:"!h-6 !w-6"}),_vm._v(" Delete ")],1):_vm._e()],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }