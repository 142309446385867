<template>
  <RfMenu
    v-bind="$attrs"
    maxHeight="400px"
    @show="(initSelected = localSelected = selected), $emit('show')"
    @hide="(localSelected = initSelected), $emit('hide')"
  >
    <template #activator>
      <slot v-if="$slots['activator'] || $scopedSlots['activator']" name="activator"></slot>
      <template v-else>
        <span class="text-body !text-black-2">{{ props.activator }}</span>
        <RfFilterIcon v-if="!props.disabled" class="!h-6 !w-6" />
      </template>
    </template>
    <template #menu="{ close }">
      <div
        class="rf-table-filter--wrapper flex max-w-max flex-shrink flex-grow-0 flex-col items-start gap-4 overflow-y-auto p-4"
      >
        <template v-for="(filter, key) in props.filters">
          <slot v-if="$slots['prepend'] || $scopedSlots['prepend']" name="prepend"></slot>
          <button
            v-if="isMultipleFilters"
            class="flex w-full items-center justify-between self-start text-left"
            @click="collapsed = { ...collapsed, [key]: !collapsed[key] }"
          >
            {{ filter.name }}
            <Transition mode="out-in">
              <RfChevronMiniClosedIcon v-if="collapsed[key]" class="!h-7 !w-7" />
              <RfChevronMiniOpenIcon v-else class="!h-7 !w-7" />
            </Transition>
          </button>
          <div
            class="flex-shrink-1 flex flex-grow-0 flex-col items-start gap-4"
            :class="{ '-mt-4 h-0 overflow-hidden': collapsed[key] }"
          >
            <RfCheckbox
              v-for="el in filter.el"
              class="rf-row--checkbox !text-sm"
              :class="{
                'rf-row--checkbox-col-selected': !!selectedHandler?.[key]?.[el[filter.key]],
                'ml-5': isMultipleFilters,
                'py-1': !($slots['item'] || $scopedSlots['item']),
              }"
              :data-cy="`table-filter--${el[filter.key]}`"
              :key="el[filter.key]"
              :value="!!selectedHandler?.[key]?.[el[filter.key]]"
              @input="selectedHandler = { selected: { ...el }, filter, key }"
            >
              <slot
                v-if="$slots['item'] || $scopedSlots['item']"
                name="item"
                :el="el"
                :value="filter.value"
              ></slot>
              <template v-else>{{ el[filter.value] }} </template>
            </RfCheckbox>
          </div>
          <hr
            v-if="isMultipleFilters"
            class="h-0 w-full border-t border-strokes last-of-type:hidden"
          />
        </template>
      </div>
      <div class="mb-2 flex w-full min-w-36">
        <button
          class="text-buttons ml-auto mr-4 p-2"
          data-cy="table-filter--apply"
          @click="$emit('selected', localSelected), (initSelected = localSelected), close()"
        >
          Apply
        </button>
      </div>
    </template>
  </RfMenu>
</template>

<script setup>
import RfCheckbox from "@/components/inputs/RfCheckbox.vue";
import RfMenu from "@/components/menus/RfMenu.vue";
import RfFilterIcon from "@/components/icons/RfFilterIcon.vue";
import RfChevronMiniClosedIcon from "@/components/icons/RfChevronMiniClosedIcon.vue";
import RfChevronMiniOpenIcon from "@/components/icons/RfChevronMiniOpenIcon.vue";
import { computed, ref } from "vue";

const props = defineProps({
  activator: { type: String, default: "Filter" },
  filters: { type: Object, default: () => ({}) },
  selected: { type: Object, default: () => {} },
});

const localSelected = ref({});
const initSelected = ref({});
const collapsed = ref({});
const isMultipleFilters = computed(() => Object.keys(props.filters).length > 1);
const selectedHandler = computed({
  get() {
    return localSelected.value;
  },
  set({ selected, filter, key }) {
    const selectedCopy = { ...localSelected.value?.[key] };
    const isExists = !!selectedCopy?.[selected[filter.key]];
    if (isExists) delete selectedCopy[selected[filter.key]];
    else selectedCopy[selected[filter.key]] = selected[filter.value];
    localSelected.value = { ...localSelected.value, [key]: selectedCopy };
  },
});
</script>

<style lang="scss" scoped>
.rf-table-filter--wrapper {
  min-width: 220px;
}
</style>
