<template>
  <v-card v-if="connectorErrors.length" class="rf-segment-sub-content" style="padding: 1rem">
    <h4>Exceptions (first 50)</h4>
    <v-simple-table class="all-action-errors-table">
      <thead>
        <tr>
          <th style="width: 130px" class="text-left">Date</th>
          <th class="text-left">User Id</th>
          <th class="text-left">Error</th>
          <th class="text-left"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="connectorError in connectorErrors"
          :key="connectorError.timestamp"
          @click="showErrorDialog(connectorError)"
        >
          <td>{{ displayDate(connectorError) }}</td>
          <td>
            <div class="single-error-details users-id-error">
              {{ fetchUserId(connectorError.parsed_message) }}
            </div>
          </td>
          <td>
            <div class="single-error-details">{{ connectorError.error }}</div>
          </td>
          <td align="right">
            <v-btn text color="primary" @click="showErrorDialog(connectorError)">Details</v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog
      v-model="showConnectorErrorDialog"
      width="700"
      content-class="show-connector-error-dialog"
    >
      <v-card flat>
        <v-card-title class="headline">Details</v-card-title>
        <v-card-text class="action-error-table-modal">
          <v-row>
            <v-col class="action-error-col-1">Date:</v-col>
            <v-col class="action-error-col-2">{{ displayDateModal(errorDetails) }}</v-col>
          </v-row>
          <v-row>
            <v-col class="action-error-col-1">User ID:</v-col>
            <v-col>{{ fetchUserId(errorParsedMessage) }}</v-col>
          </v-row>
          <v-row>
            <v-col class="action-error-col-1">Error:</v-col>
            <v-col class="action-error-col-2" v-if="individualErrors || clientAction">
              {{ errorDetails.error }}
            </v-col>
            <v-col class="action-error-col-2" v-else
              >All actions failed with message: {{ errorDetails.error }}</v-col
            >
          </v-row>
          <v-row v-if="individualErrors">
            <v-col class="action-error-col-1">Note:</v-col>
            <v-col class="action-error-col-2">Actions that succeeded are not included here</v-col>
          </v-row>
          <v-simple-table class="rf-settings-table-data" style="margin-top: 20px">
            <thead>
              <tr>
                <th v-if="clientAction">Action Type</th>
                <th v-else>Connector</th>
                <th style="width: 120px" v-if="clientAction">Action Name</th>
                <th style="width: 120px" v-else>Action Type</th>
                <th>Error</th>
              </tr>
            </thead>
            <tbody v-if="individualErrors || clientAction">
              <tr v-for="(error, index) in errorDetails.error_messages" :key="index">
                <td>{{ displayConnectorType(error.message) }}</td>
                <td>{{ error.message.description || error.message.operation }}</td>
                <td>{{ error.error }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(item, index) in errorParsedMessage.items" :key="index">
                <div v-if="item">
                  <td>{{ displayConnectorType(item) }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ errorDetails.error }}</td>
                </div>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <RfButton
            icon="clear"
            button-text="Delete"
            color="error"
            class="px-7"
            :disabled="isDisabledRoleMixin"
            @click="deleteError(errorDetails)"
          />
          <RfButton
            v-if="!clientAction"
            icon="autorenew"
            button-text="Retry"
            color="primary"
            class="px-7"
            :disabled="isDisabledRoleMixin"
            @click="retryAndDelete(errorDetails)"
          />
          <RfButton button-text="Close" outlined class="cancel-btn px-5" @click="closeDialog" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import RoleMixin from "@/utils/RoleMixin";
import RfButton from "@/components/buttons/RfButton.vue";
import { useToastsStore } from "@/pinia/toastsStore";
import { dateOrder } from "@/utils/DateDisplayUtils";

export default {
  name: "RfConnectorErrors",
  setup: () => ({ toastsStore: useToastsStore() }),
  components: { RfButton },
  mixins: [RoleMixin],
  data() {
    return {
      updating: false,
      showConnectorErrorDialog: false,
      errorDetails: {},
      errorParsedMessage: {},
    };
  },
  computed: {
    ...mapState({
      path: state => state.apps.currPath,
      lastError: state => state.lastError,
    }),
    clientAction() {
      return this.errorParsedMessage.client_action;
    },
    connectorErrors() {
      if (this.path) {
        return this.path.connector_errors;
      }
      return [];
    },
    individualErrors() {
      return (
        this.errorDetails.error_messages &&
        this.errorDetails.error_messages.length &&
        !this.clientAction
      );
    },
  },
  methods: {
    ...mapActions(["retryAndDeleteConnectorError", "deleteConnectorError", "getPath"]),
    showErrorDialog(errorDetails) {
      this.showConnectorErrorDialog = true;
      this.errorDetails = errorDetails;
      this.errorParsedMessage = errorDetails.parsed_message;
    },
    closeDialog() {
      this.showConnectorErrorDialog = false;
    },
    displayDate(error) {
      const { timestamp } = error;
      if (timestamp) {
        return moment(new Date(timestamp * 1000)).format(`${dateOrder()}/YYYY`);
      }
      return "N/A";
    },
    displayDateModal(error) {
      const { timestamp } = error;
      if (timestamp) {
        return moment(new Date(timestamp * 1000)).format("MMM Do YYYY, h:mm a");
      }
      return "N/A";
    },
    async retryAndDelete(error) {
      this.updating = true;
      await this.retryAndDeleteConnectorError({
        appId: this.path.app_id,
        pathId: this.path.id,
        connectorError: error,
      });
      await this.getPath({
        appId: this.path.app_id,
        pathId: this.path.id,
      });
      if (this.updating) {
        this.toastsStore.create({ type: "success", body: "Retry Successful" });
        this.updating = false;
        this.closeDialog();
      }
    },
    async deleteError(error) {
      this.updating = true;
      await this.deleteConnectorError({
        appId: this.path.app_id,
        pathId: this.path.id,
        connectorError: error,
      });
      await this.getPath({
        appId: this.path.app_id,
        pathId: this.path.id,
      });
      if (this.updating) {
        this.toastsStore.create({ type: "success", body: "Error deleted" });
        this.updating = false;
        this.closeDialog();
      }
    },
    displayConnectorType(error) {
      if (error) {
        const { provider } = error;
        if (provider) {
          return provider.replace(/^\w/, c => c.toUpperCase()); // capitalize first letter
        }
      }
      return "";
    },
    fetchUserId(error) {
      return error.user_id;
    },
  },
  watch: {
    lastError(to) {
      if (to && this.updating) {
        this.updating = false;
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
  },
};
</script>
